import { Select, SelectProps } from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/es/select";
import { BaseSelectRef } from "rc-select";
import React, { Ref, useRef, useState } from "react";

interface TMultiSelectProps<VT = any, OT extends BaseOptionType | DefaultOptionType = DefaultOptionType> extends SelectProps<VT, OT> {
  maxCount?: number;
}

const TMultiSelect = <VT = any, OT extends BaseOptionType | DefaultOptionType = DefaultOptionType>(props: TMultiSelectProps<VT, OT> & { ref?: Ref<BaseSelectRef>}) => {

  const {
    ref,
    options,
    onChange,
    maxCount = 99999,
    ...restProps
  } = props;

  const selectedRef = useRef<VT[]>();

  const [ maxed, setMaxed ] = useState<boolean>(false);

  const _onChange: TMultiSelectProps<VT, OT>["onChange"] = (value, option) => {
    onChange?.(value, option);
    if (!Array.isArray(value)) {
      return;
    }
    selectedRef.current = value;
    setMaxed(value.length >= maxCount);
  };

  return (
    <Select
      ref={ref}
      mode="multiple"
      allowClear
      onChange={_onChange}
      options={maxed ? options?.map((_) => {
        _.disabled = !selectedRef.current?.includes(_.value);
        return _;
      }) : options?.map((_) => {
        _.disabled = false;
        return _;
      })}
      {...restProps}
    />
  );
};

export default TMultiSelect;

import { PlusOutlined, QuestionOutlined } from "@ant-design/icons";
import React, { RefObject, useRef, useState } from "react";
import { ColorChangeHandler, SketchPicker } from "react-color";
import { createUseStyles } from "react-jss";
import TPopover, { TPopoverInstance } from "~src/components/TPopover";
import TTypography from "~src/components/TTypography";
import theme from "~src/theme";
import * as types from "~src/types";


interface TColourPickerProps {
  currentColourState: ReturnType<typeof useState<string>>;
  coloursState: types.ReactNonNullableState<string[]>;
}

const TColourPicker: React.FC<TColourPickerProps> = (props) => {

  const {
    currentColourState: [
      currentColour,
      setCurrentColour,
    ],
    coloursState: [
      colours,
      setColours,
    ],
  } = props;

  const addColourRef = useRef<HTMLDivElement>(null);
  const colourPickerRef = useRef<TPopoverInstance>(null);

  const styles = useStyles();


  const onItemClick = (colour: string) => {
    setCurrentColour(colour);
  };

  const onAddColourClick = () => {
    colourPickerRef.current!.open(true);
  };

  const onSketchChangeComplete: ColorChangeHandler = (color) => {
    setCurrentColour(color.hex);
  };
  const onPopoverRequestClose = () => {
    if (colours?.find((v) => v === currentColour) !== undefined) {
      return;
    }
    setColours([...colours || [], currentColour!]);
  };

  const render: JSX.Element = (<>
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
      }}
    >
      {colours?.map((v, i) =>
        <div
          key={i}
          className={styles.colourContainer}
          style={{
            background: v,
            ...(v === currentColour && {
              border: `1px solid ${theme.palette.blackMask(.4)}`,
              boxShadow: `0 2px 6px ${theme.palette.blackMask(.4)}`,
            } satisfies React.CSSProperties),
          }}
          onClick={() => onItemClick(v)}
        >
          <div
            className={styles.colourTextContainer}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TTypography
              style={{
                color: theme.palette.whiteMask(.8),
              }}
            >
              {v}
            </TTypography>
          </div>
        </div>
      )}
      <div
        ref={addColourRef}
        className={styles.colourContainer}
        style={{
          opacity: .4,
          border: "1px solid black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={onAddColourClick}
      >
        <PlusOutlined />
      </div>
    </div>
  </>);

  const renderColourPicker: JSX.Element = (<>
    <TPopover
      ref={colourPickerRef}
      anchor={addColourRef}
      position="BottomLeft"
      onRequestClose={onPopoverRequestClose}
    >
      <div
        style={{
          borderRadius: "4px",
          border: `1px solid ${theme.palette.blackMask(.2)}`,
        }}
      >
        <SketchPicker
          color={currentColour}
          onChangeComplete={onSketchChangeComplete}
        />
      </div>
    </TPopover>
  </>);

  return (<>
    {render}
    {renderColourPicker}
  </>);
};

// const Picker: React.FC = () => {
//
// }

const useStyles = createUseStyles({
  colourContainer: {
    width: "64px",
    height: "64px",
    boxSizing: "border-box",
    cursor: "pointer",
    borderRadius: "8px",
    transition: "border .1s, box-shadow .1s",
  },
  colourTextContainer: {
    opacity: 0,
    transition: "opacity .2s",
    "&:hover": {
      opacity: 1,
    },
  },
});

export default TColourPicker;

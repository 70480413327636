import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";
import theme from "~src/theme";

interface TDivProps extends React.ComponentPropsWithRef<"div"> {
  hoverEffect?: boolean;
}

const TDiv = React.forwardRef<HTMLDivElement, TDivProps>((props, ref) => {

  const {
    hoverEffect = true,
    className,
    ...restProps
  } = props;

  const styles = useStyles();


  return (
    <div
      ref={ref}
      className={classNames({ [styles.divHover]: true }, className)}
      {...restProps}
    />
  );
});

const useStyles = createUseStyles({
  divHover: {
    position: "relative",
    overflow: "hidden",
    "&::after": {
      display: "block",
      position: "absolute",
      inset: 0,
      content: "''",
    },
    "&:hover::after": {
      background: theme.palette.blackMask(.05),
    },
    "&:active::after": {
      background: theme.palette.blackMask(.08),
    },
  },
});

export default TDiv;

import React, { useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import bridge from "~src/backend/bridge";
import T from "~src/components";
import TModal, { TModalProps } from "~src/components/TModal";
import TNotification, { TNotificationInstance } from "~src/components/TNotification";
import theme from "~src/theme";

const ChangePassword = React.forwardRef<ChangePasswordInstance, Omit<TModalProps, "isOpen">>((props, ref) => {

  const {
    style: {
      overlay: overlayStyle,
      content: contentStyle,
    } = {},
    onRequestClose,
    ...restProps
  } = props;

  const oldPasswordInputRef = useRef<HTMLInputElement>(null);
  const newPasswordInputRef = useRef<HTMLInputElement>(null);
  const newPassword2InputRef = useRef<HTMLInputElement>(null);
  const notificationRef = useRef<TNotificationInstance>(null);

  const [ open, setOpen ] = useState<boolean>(false);

  const { t } = useTranslation();

  const onChangePasswordClick = async () => {
    const oldPassword = oldPasswordInputRef.current!.value;
    const newPassword = newPasswordInputRef.current!.value;
    const newPassword2 = newPassword2InputRef.current!.value;

    const invalidFields: string[] = [];
    if (!oldPassword) {
      invalidFields.push("old password");
    }
    if (!newPassword) {
      invalidFields.push("new password");
    }
    if (!newPassword2) {
      invalidFields.push("repeated new password");
    }
    if (invalidFields.length > 0) {
      notificationRef.current?.open("ERROR", `invalid field(s): ${invalidFields.join(" / ")}`);
      return false;
    }
    if (newPassword !== newPassword2) {
      notificationRef.current?.open("ERROR", "new passwords mismatch");
      return false;
    }

    const r = await bridge.changePassword({oldPassword, newPassword});
    if (r?.status !== "OK") {
      notificationRef.current!.open("ERROR", r?.data || "cannot change password");
      return false;
    }

    setOpen(false);
    notificationRef.current!.open("OK", "password has been updated");
    return true;
  };

  const onCancelClick = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => {
    return {
      setOpen: (_) => {
        setOpen(_);
      },
    };
  }, []);

  return (<>
    <TModal
      isOpen={open}
      style={{
        overlay: {
          ...overlayStyle,
        },
        content: {
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          ...contentStyle,
        },
      }}
      onRequestClose={(_) => {
        onRequestClose?.(_);
        setOpen(false);
      }}
      {...restProps}
    >
      <T.Typography
        style={{
          fontSize: "24px",
          fontWeight: 600,
          color: theme.palette.dark,
          textAlign: "center",
        }}
      >
        {t("change_password")}
      </T.Typography>
      <T.Gap
        height={32}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <T.Input
          ref={oldPasswordInputRef}
          type="password"
          placeholder={t("old_password")}
        />
        <T.Input
          ref={newPasswordInputRef}
          type="password"
          placeholder={t("new_password")}
        />
        <T.Input
          ref={newPassword2InputRef}
          type="password"
          placeholder={t("password2")}
        />
      </div>
      <T.Gap
        height={24}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          gap: "16px",
        }}
      >
        <T.Button
          onClick={onChangePasswordClick}
        >
          {t("confirm")}
        </T.Button>
        <T.Button
          onClick={onCancelClick}
        >
          {t("cancel")}
        </T.Button>
      </div>
    </TModal>
    <TNotification
      ref={notificationRef}
    />
  </>);
});

interface ChangePasswordInstance {
  setOpen: (_: boolean) => void;
}

export default ChangePassword;
export type {
  ChangePasswordInstance,
};

import { blue, gold, orange, red } from "@ant-design/colors";
import toolbox from "~src/toolbox";

const primaryPalette = blue;

const palette = {
  primary: primaryPalette,
  gold: gold,
  red: red,
  orange: orange,
  neutral: [
    '#ffffff',
    '#fafafa',
    '#f5f5f5',
    '#f0f0f0',
    '#d9d9d9',
    '#bfbfbf',
    '#8c8c8c',
    '#595959',
    '#434343',
    '#262626',
    '#1f1f1f',
    '#141414',
    '#000000',
  ],
  black: "#000000",
  dark: "#333333",
  dark2: "#292929",
  light: "#FFFFFF",
  preset: [
    "#40a9ff",
    "#13c2c2",
    "#6ac39e",
    "#ea8072",
    "#a37bb0",
    "#c7bd89",
    "#faa962",
    "#6a7583",
    "#7fa3c5",
    "#b89289",
  ],
  primaryMask: (index: number, alpha: number): string => toolbox.colourHexAlpha(primaryPalette[index], alpha),
  blackMask: (alpha: number): string => toolbox.colourHexAlpha('#000000', alpha),
  whiteMask: (alpha: number): string => toolbox.colourHexAlpha('#FFFFFF', alpha),
};

enum zIndex {
  default,
  topBar,
  modal,
}

export default {
  palette,
  zIndex,
};

import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";

interface TIconProps extends React.SVGProps<SVGSVGElement> {
  hover?: boolean;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

const TIcon = React.forwardRef<SVGSVGElement, TIconProps>( (props, ref) => {

  const {
    className,
    hover = false,
    icon: Icon,
    ...restProps
  } = props;

  const styles = useStyles();

  return (
    <Icon
      ref={ref}
      className={classNames(
        {[styles.clickable]: props.onClick},
        {[styles.hover]: hover},
        className,
      )}
      {...restProps}
    />
  );
});

const useStyles = createUseStyles({
  "clickable": {
    cursor: "pointer",
  },
  "hover": {
    opacity: .6,
    "&:hover": {
      opacity: 1,
    },
  },
});

export default TIcon;

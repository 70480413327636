import classNames from "classnames";
import React, {
  ChangeEventHandler,
  CSSProperties,
  Fragment,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Provider } from "react-redux";
import backend from "~src/backend";
import bridge from "~src/backend/bridge";
import T from "~src/components";
import config from "~src/config";
import R from "~src/res";
import theme from "~src/theme";
import toolbox from "~src/toolbox";
import * as types from "~src/types";

const plan = backend.Plan;

const Purchase: React.FC = () => {

  const orderInfoRef = useRef<OrderInfo>();
  const notificationRef = useRef<types.TNotificationInstance>(null);
  const userInfoRef = useRef<{
    username?: string;
    role?: string;
  }>({});

  const appNameInputRef = useRef<HTMLInputElement>(null);
  const platformRef = useRef<string[]>();
  const couponInputRef = useRef<HTMLInputElement>(null);

  const [ purchaseModalOpen, setPurchaseModalOpen ] = useState<boolean>(false);
  const [ couponStatus, setCouponStatus ] = useState<types.InputStatus>();
  const [ couponRate, setCouponRate ] = useState<number>();

  const navigate = toolbox.useNavigate();

  const { t } = useTranslation();

  const checkCoupon = async (code: string) => {
    const r = await bridge.checkCoupon({
      code,
    });
    if (r?.status !== "OK") {
      notificationRef.current!.open("ERROR", r?.data as string || "unknown");
      setCouponStatus("error");
      return;
    }
    setCouponRate((r.data as types.CheckCoupon).discount);
    setCouponStatus(undefined);
  };

  const onInquiry: MouseEventHandler<HTMLButtonElement> = () => {
    // todo: on inquiry
    window.open(config.customerServiceUrl);
  };

  const onLearnMore: MouseEventHandler<HTMLButtonElement> = () => {
    // todo; on learn more
  };

  const onPurchase: PurchaseHandler = (orderInfo) => {
    if (userInfoRef.current.username === undefined) {
      navigate("/login");
    }
    orderInfoRef.current = orderInfo;
    setPurchaseModalOpen(true);
  };

  // purchase modal

  const onCouponInputChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
    const coupon = event.target.value;

    if (couponRate !== undefined) {
      setCouponRate(undefined);
    }

    switch (coupon.length) {
      case 0: {
        if (couponStatus !== undefined) {
          setCouponStatus(undefined);
        }
        break;
      }
      case 16: {
        await checkCoupon(coupon);
        break;
      }
      default: {
        setCouponStatus("warning");
        break;
      }
    }
  };

  const onPlatformsChange = (value: string[]) => {
    platformRef.current = value;
  };

  const onPurchaseConfirm = async () => {
    const platformId = orderInfoRef.current!.platformId;
    const userId = orderInfoRef.current!.userId;
    const periodId = orderInfoRef.current!.periodId;
    const appName = appNameInputRef.current!.value;
    const platforms = platformRef.current!;
    const coupon = couponInputRef.current!.value;

    const r = await bridge.createOrder({
      platform: platformId,
      user: userId,
      period: periodId,
      name: appName,
      platformNames: platforms,
      coupon,
    });
    if (r?.status !== "OK") {
      notificationRef.current!.open("ERROR", r?.data as string || "unknown");
      return;
    }
    navigate("/user/order");
  };

  useEffect(() => {
    return toolbox.useAsyncEffectOnce(async () => {
      const r = await bridge.isOnline();
      if (r?.status !== "OK") {
        return;
      }
      userInfoRef.current.username = r?.data.username;
      userInfoRef.current.role = r?.data.role;
    });
  }, []);

  const planData = [
    {
      icon: R.PlatformsIcon,
      type: "platform",
      title: "选择平台",
      subtitle: "目前拥有 Windows、Android、macOS 客户端，可选择任意客户端搭配购买。",
      items: backend.Plan.Platforms,
    },
    {
      icon: R.UsersIcon,
      type: "user",
      title: "用户数",
      subtitle: "根据您的用户数量选择不同的套餐。",
      items: backend.Plan.Users,
    },
    {
      icon: R.TimeIcon,
      type: "period",
      title: "订阅周期",
      subtitle: "选择你需要的产品有效周期。",
      items: backend.Plan.Periods,
    },
  ] satisfies PlanData[];

  const render = (<>
    <div
      style={{
        width: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          position: "relative",
        }}
      >
        <T.Img
          style={{
            maxWidth: "100%",
            minHeight: "360px",
            aspectRatio: 1920 / 360,
          }}
          src={R.PurchaseBanner}
        />
        <T.TopBar
          active="purchase"
        />
        <T.Typography
          style={{
            position: "absolute",
            inset: "50% auto auto 23.96%",
            fontWeight: 500,
            fontSize: "48px",
            lineHeight: "67px",
            color: theme.palette.light,
          }}
        >
          产品订购
        </T.Typography>
        <button
          style={{
            position: "absolute",
            inset: "53.61% 23.96% auto auto",
            minWidth: "144px",
            height: "48px",
            cursor: "pointer",
            border: `2px solid ${theme.palette.light}`,
            borderRadius: "999px",
            background: "transparent",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          type="button"
          onClick={onInquiry}
        >
          <T.Typography
            style={{
              fontSize: "16px",
              lineHeight: "22px",
              color: theme.palette.light,
            }}
          >
            售前咨询
          </T.Typography>
        </button>
      </div>
      <T.Gap
        height={80}
      />
      <PlanSelector
        data={planData}
        onPurchase={onPurchase}
      />
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          background: theme.palette.neutral[2],
          display: "flex",
          flexDirection: "column",
        }}
      >
        <R.LogoBackground
          style={{
            position: "absolute",
            inset: "0 -3.96% auto auto",
            width: "25%",
            height: "auto",
            transform: "translateY(-21.54%)",
          }}
        />
        <T.Gap
          height={80}
        />
        <T.Typography
          style={{
            alignSelf: "center",
            fontWeight: 500,
            fontSize: "32px",
            lineHeight: "45px",
            color: theme.palette.black,
          }}
        >
          价格对比
        </T.Typography>
        <T.Gap
          height={48}
        />
        <PricingTable
          tabs={
            // todo: remove this filter when iOS is ready
            plan.Platforms.filter(_ => !_.title.includes("iOS"))
          }
          rows={plan.Periods}
          columns={plan.Users}
        >
          <div
            style={{
              display: "flex",
              alignSelf: "center",
              opacity: .3,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "18px",
              }}
            >
              <R.MacOSIcon
                width={18}
                height={18}
              />
              <R.WindowsIcon
                width={18}
                height={18}
              />
              <R.AndroidIcon
                width={18}
                height={18}
              />
            </div>
            <T.Gap
              width={16}
            />
            <T.Typography
              style={{
                fontSize: "16px",
                lineHeight: "22px",
                color: theme.palette.black,
              }}
            >
              平台从Windows、Android、macOS中任选
            </T.Typography>
          </div>
        </PricingTable>
        <T.Gap
          height={120}
        />
      </div>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <T.Img
          style={{
            width: "100%",
            aspectRatio: 1920 / 538,
          }}
          src={R.PurchaseBackgroundImage}
        />
        <div
          style={{
            position: "absolute",
            inset: "14.87% 16.67% 11.15%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <T.Typography
            style={{
              alignSelf: "center",
              fontWeight: 600,
              fontSize: "32px",
              lineHeight: "45px",
              color: theme.palette.light,
            }}
          >
            Why Vortex
          </T.Typography>
          <div
            style={{
              position: "relative",
              top: "-8px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <FeatureItem
              icon={R.TechIcon}
              title="核心科技"
              subtitle="先进架构，全平台性能加速"
            />
            <FeatureItem
              icon={R.ServiceIcon}
              title="安心服务"
              subtitle="售前售后跟踪，及时响应需求"
            />
            <FeatureItem
              icon={R.EasyIcon}
              title="轻松部署"
              subtitle="简单几步定制专属产品"
            />
          </div>
          <button
            style={{
              alignSelf: "center",
              minWidth: "144px",
              height: "48px",
              cursor: "pointer",
              border: `2px solid ${theme.palette.light}`,
              borderRadius: "999px",
              background: "transparent",
            }}
            type="button"
            onClick={onLearnMore}
          >
            <T.Typography
              style={{
                fontSize: "16px",
                lineHeight: "22px",
                color: theme.palette.light,
              }}
            >
              了解更多
            </T.Typography>
          </button>
        </div>
      </div>
      <T.BottomBar />
    </div>
  </>);

  const renderModal: JSX.Element = (() => {

    const price = orderInfoRef.current ?  plan.priceCalculator(
      orderInfoRef.current?.platformId,
      orderInfoRef.current?.userId,
      orderInfoRef.current?.periodId,
    ) : undefined;

    const discountedText = ((discount: number | undefined) => {
      if (discount === undefined) {
        return "";
      }
      const percentage = Math.round(discount * 100);
      return `(${percentage}% off)`;
    })(couponRate);

    const discountedPrice = ((price: number | undefined, discount: number | undefined) => {
      if (price === undefined || discount === undefined) {
        return "0";
      }
      const discounted = Math.round(price * discount * 100) / 100;
      return `－ $${discounted}`;
    })(price, couponRate);

    const onClose = () => {
      setPurchaseModalOpen(false);
    };

    return (
      <T.Modal
        onRequestClose={onClose}
        isOpen={purchaseModalOpen}
      >
        <div
          style={{
            minWidth: "320px",
            boxSizing: "border-box",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <T.Typography
              style={{
                alignSelf: "center",
                fontWeight: 600,
                fontSize: "16px",
                color: theme.palette.dark,
              }}
            >
              {t("order_configuration")}
            </T.Typography>
            <T.Icon
              icon={R.CloseIcon}
              style={{
                padding: "6px",
              }}
              onClick={onClose}
            />
          </div>
          <T.Gap
            height={16}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PurchaseModalTextItem
              title={t("product")}
              content={`${orderInfoRef.current?.platformTitle} • ${orderInfoRef.current?.userTitle} • ${orderInfoRef.current?.periodTitle}`}
            />
            {orderInfoRef.current &&
              <PurchaseModalTextItem
                title={t("price")}
                content={`$ ${price}`}
              />
            }
            <PurchaseModalTextItem
              title={`${t("discount")} ${discountedText}`}
              content={couponRate ? discountedPrice : "0"}
              contentStyle={{
                ...(couponRate && {
                  color: theme.palette.gold[5],
                } satisfies CSSProperties),
              }}
            />
          </div>
          <T.Divider
            style={{
              margin: "12px 0",
            }}
          />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              alignItems: "center",
              gap: "12px 16px",
            }}
          >
            <PurchaseModalInputItem
              ref={appNameInputRef}
              title={t("application_name")}
              placeholder={t("unchangeable_after_created")}
            />
            <T.Typography
              style={{
                fontSize: "14px",
                color: theme.palette.dark,
              }}
            >
              {t("platforms")}
            </T.Typography>
            <T.MultiSelect
              maxCount={orderInfoRef.current?.platformId}
              options={Object.values(bridge.Platform).map((_) => ({
                label: _,
                value: _,
              }))}
              onChange={onPlatformsChange}
            />
            <PurchaseModalInputItem
              ref={couponInputRef}
              title={t("coupon_code")}
              status={couponStatus}
              onChange={onCouponInputChange}
            />
          </div>
          <T.Gap
            height={24}
          />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
              gap: "16px",
            }}
          >
            <T.Button
              onClick={() => onPurchaseConfirm()}
            >
              {t("confirm")}
            </T.Button>
            <T.Button
              onClick={onClose}
            >
              {t("cancel")}
            </T.Button>
          </div>
        </div>
      </T.Modal>
    );
  })();

  const renderNotification: JSX.Element = (<>
    <T.Notification
      ref={notificationRef}
    />
  </>);

  return (
    <Provider store={backend.redux.PurchaseStore}>
      {render}
      {renderModal}
      {renderNotification}
    </Provider>
  );
};

const PlanSelector: React.FC<{
  data: PlanData[];
  onPurchase?: PurchaseHandler;
}> = (props) => {

  const {
    data,
    onPurchase,
  } = props;

  const selector = backend.redux.useActivePlanSelector((state) => state.activePlan);

  let offline = '';
  if (selector.user.id === 60000 && selector.period.id === 100) {
    offline = ' • 离线授权';
  }

  const _onPurchase: MouseEventHandler<HTMLButtonElement> = () => {
    onPurchase?.({
      platformId: selector.platform.id,
      platformTitle: selector.platform.title,
      userId: selector.user.id,
      userTitle: selector.user.title,
      periodId: selector.period.id,
      periodTitle: selector.period.title,
    });
  };

  return (
    <div
      style={{
        margin: "0 23.96%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "108px",
        }}
      >
        {data.map((v, i) =>
          <PlanSelectorGroup
            key={i}
            {...v}
          />
        )}
      </div>
      <T.Gap
        height={174}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <T.Typography
            style={{
              fontWeight: 600,
              fontSize: "48px",
              lineHeight: "67px",
              color: theme.palette.primary[4],
            }}
          >
            {/* todo: amount */}
            $ {plan.priceCalculator(selector.platform.id, selector.user.id, selector.period.id)}
          </T.Typography>
          <T.Typography
            style={{
              fontSize: "16px",
              lineHeight: "22px",
              color: theme.palette.blackMask(.5),
            }}
          >
            {/* todo: selected plan */}
            {selector.platform.title} • {selector.user.title} • {selector.period.title}{offline}
          </T.Typography>
        </div>
        <button
          style={{
            minWidth: "320px",
            height: "72px",
            cursor: "pointer",
            border: "none",
            borderRadius: "999px",
            background: theme.palette.primary[4],
          }}
          type="button"
          onClick={_onPurchase}
        >
          <T.Typography
            style={{
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "25px",
              color: theme.palette.light,
            }}
          >
            立即购买
          </T.Typography>
        </button>
      </div>
      <T.Gap
        height={120}
      />
    </div>
  );
};

const PlanSelectorGroup: React.FC<PlanData> = (props) => {

  const selector = backend.redux.useActivePlanSelector((state) => state.activePlan);
  let items = JSON.parse(JSON.stringify(props.items)) as types.PlanItemInterface[];
  items.forEach(item => {
    if (item.title.includes("人")) {
      let num = item.title.match(/\d+/);
      if (num) {
        item.title = `${Number(num[0]) * selector.platform.id}人以下`;
      }
    }
  });

  const {
    icon: Icon,
    type,
    title,
    subtitle,
    // items,
  } = props;

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          position: "absolute",
          inset: "0 calc(100% + 24px) auto auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Icon />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
        }}
      >
        <T.Typography
          style={{
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "34px",
            color: theme.palette.dark,
          }}
        >
          {title}
        </T.Typography>
        <T.Typography
          style={{
            fontSize: "16px",
            lineHeight: "22px",
            color: theme.palette.blackMask(.5),
          }}
        >
          {subtitle}
        </T.Typography>
      </div>
      <T.Gap
        height={32}
      />
      <div
        style={{
          alignSelf: "stretch",
          display: "grid",
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
          gap: "50px",
        }}
      >
        {items.map((v, i) =>
          <PlanSelectorItem
            key={i}
            type={type}
            item={v}
          />
        )}
      </div>
    </div>
  );
};

const PlanSelectorItem: React.FC<{
  type: keyof types.ActivePlanState;
  item: types.PlanItemInterface;
}> = (props) => {

  const {
    type,
    item,
  } = props;

  const slice = backend.redux.activePlanSlice;
  const selector = backend.redux.useActivePlanSelector((state) => state.activePlan[type]);
  const dispatch = backend.redux.useActivePlanDispatch();

  const active = item.id === selector.id;

  const onClick = () => {
    if (item.disabled) {
      return;
    }
    const reducer = slice.actions[backend.redux.activePlanReducerMap[type]];
    dispatch(reducer(item));
  };

  return (
    <div
      style={{
        height: "108px",
        boxSizing: "border-box",
        cursor: item.disabled ? "not-allowed" : "pointer",
        border: active ? `4px solid ${theme.palette.primary[4]}` : `2px solid ${theme.palette.blackMask(.1)}`,
        borderRadius: "16px",
        background:  item.disabled ? theme.palette.blackMask(.05) : undefined,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
      }}
      onClick={onClick}
    >
      <T.Typography
        style={{
          fontWeight: 500,
          fontSize: "18px",
          lineHeight: "25px",
          color: active ? theme.palette.primary[4] : theme.palette.dark,
          opacity: item.disabled ? .3 : undefined,
        }}
      >
        {item.title}
      </T.Typography>
      <T.Typography
        style={{
          display: item.description ? "inherit" : "none",
          fontSize: "14px",
          lineHeight: "20px",
          color: active ? theme.palette.primary[4] : theme.palette.blackMask(.5),
          opacity: item.disabled ? .5 : undefined,
        }}
      >
        {item.description}
      </T.Typography>
    </div>
  );
};

const PricingTable: React.FC<React.ComponentPropsWithRef<"table"> & {
  tabs: types.PlanItemInterface[];
  rows: types.PlanItemInterface[];
  columns: types.PlanItemInterface[];
}> = (props) => {

  let {
    tabs,
    rows,
    columns,
    // eslint-disable-next-line react/prop-types
    className,
    ...restProps
  } = props;

  const [ tab, setTab ] = useState<typeof tabs[0]>(tabs[0]);

  const styles = useStyles();

  const onTabClick = (_: typeof tab) => {
    setTab(_);
  };

  className = classNames(styles.table, className);

  return (
    <div
      style={{
        margin: "0 23.96%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          alignSelf: "center",
          padding: "2px",
          height: "48px",
          boxSizing: "border-box",
          borderRadius: "999px",
          background: theme.palette.blackMask(.05),
          display: "flex",
        }}
      >
        {tabs.map((v) =>
          <button
            key={v.id}
            style={{
              minWidth: "128px",
              cursor: "pointer",
              border: "none",
              borderRadius: "999px",
              background: tab === v ? theme.palette.light : "none",
            }}
            type="button"
            onClick={() => onTabClick(v)}
          >
            <T.Typography
              style={{
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "22px",
                color: tab.id === v.id ? theme.palette.dark : theme.palette.blackMask(.5),
              }}
            >
              {v.title}
            </T.Typography>
          </button>
        )}
      </div>
      <T.Gap
        height={48}
      />
      <div
        style={{
          background: theme.palette.light,
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.05)",
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <table
          className={className}
          {...restProps}
        >
          <tbody>
          <tr>
            <td />
            {columns.map(column =>
              <td
                key={column.id}
              >
                {column.title}
              </td>
            )}
          </tr>
          {rows.map((row) =>
            <Fragment
              key={row.id}
            >
              <tr
                style={{
                  height: "64px",
                }}
              >
                <td />
                {columns.map((_, i) =>
                  <td
                    key={i}
                  />
                )}
              </tr>
              <tr
                key={row.id}
              >
                <td>
                  <T.Typography
                    style={{
                      fontSize: "16px",
                      lineHeight: "22px",
                      color: theme.palette.dark,
                    }}
                  >
                    {row.title}
                  </T.Typography>
                </td>
                {columns.map((column) =>
                  <td
                    key={column.id}
                  >
                    <T.Typography
                      style={{
                        fontSize: "16px",
                        lineHeight: "22px",
                        color: theme.palette.blackMask(.5),
                      }}
                    >
                      $ {plan.priceCalculator(tab.id, column.id, row.id)}
                    </T.Typography>
                  </td>
                )}
              </tr>
            </Fragment>
          )}
          </tbody>
        </table>
        <T.Gap
          height={72}
        />
        {props.children}
        <T.Gap
          height={32}
        />
      </div>
    </div>
  );
};

const FeatureItem: React.FC<{
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  subtitle: string;
}> = (props) => {

  const {
    icon: Icon,
    title,
    subtitle,
  } = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <Icon />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
        }}
      >
        <T.Typography
          style={{
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "25px",
            color: theme.palette.light,
          }}
        >
          {title}
        </T.Typography>
        <T.Typography
          style={{
            fontSize: "16px",
            lineHeight: "22px",
            color: theme.palette.whiteMask(.5),
          }}
        >
          {subtitle}
        </T.Typography>
      </div>
    </div>
  );
};

const PurchaseModalTextItem: React.FC<{
  title: string;
  content: string;
  contentStyle?: CSSProperties;
}> = (props) => {

  const {
    title,
    content,
    contentStyle,
  } = props;

  return (
    <div
      style={{
        height: "36px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <T.Typography
        style={{
          fontSize: "14px",
          color: theme.palette.neutral[7],
        }}
      >
        {title}
      </T.Typography>
      <T.Typography
        style={{
          color: theme.palette.blackMask(.45),
          ...contentStyle,
        }}
      >
        {content}
      </T.Typography>
    </div>
  );
};

const PurchaseModalInputItem = React.forwardRef<
  HTMLInputElement, {
  title: string;
  placeholder?: string;
  status?: types.InputStatus;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}>((props, ref) => {

  const {
    title,
    placeholder,
    status,
    onChange,
  } = props;

  return (<>
    <T.Typography
      style={{
        fontSize: "14px",
        color: theme.palette.dark,
      }}
    >
      {title}
    </T.Typography>
    <T.Input
      ref={ref}
      placeholder={placeholder}
      onChange={onChange}
      status={status}
    />
  </>);
});

const useStyles = createUseStyles({
  table: {
    padding: "72px 8%",
    width: "100%",
    "& tr": {
      width: "100%",
    },
    "& td:first-child": {
      width: "23.81%",
    },
    "& td:not(:first-child):not(:last-child)": {
      width: "28.57%",
      textAlign: "center",
    },
    "& td:last-child": {
      width: "19.05%",
      textAlign: "right",
    },
    "& td:not(:first-child)": {
      borderLeft: `1.5px solid ${theme.palette.blackMask(.05)}`,
    },
  },
});

interface PlanData {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  type: keyof types.ActivePlanState;
  title: string;
  subtitle: string;
  items: types.PlanItemInterface[];
}

type OrderInfo = {
  platformId: number;
  platformTitle: string;
  userId: number;
  userTitle: string;
  periodId: number;
  periodTitle: string;
};

type PurchaseHandler = (orderInfo: OrderInfo) => void;

export default Purchase;

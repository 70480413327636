import { Table, TableProps } from "antd";
import Column from "antd/es/table/Column";
import React, { Ref } from "react";

interface TAntTableProps<T> extends TableProps<T> {
}

const _TAntTable = <T extends object,>(props: TAntTableProps<T> & { ref?: Ref<HTMLDivElement>}) => {

  const {
    ref,
    ...restProps
  } = props;

  return (
    <Table
      ref={ref}
      {...restProps}
    />
  );
};

const TAntTable: typeof _TAntTable & {
  Column: typeof Column;
} = Object.assign(
  _TAntTable,
  { Column },
);

export default TAntTable;

import React, { MouseEventHandler, useRef } from "react";
import Collapsible from "react-collapsible";
import T from "~src/components";
import R from "~src/res";
import theme from "~src/theme";

const Support: React.FC = () => {

  const onMailClick: MouseEventHandler<HTMLDivElement> = () => {
    // todo: use actual email address
    window.location.href = "mailto:sales@vtx.team";
  };

  const onTelegramClick: MouseEventHandler<HTMLDivElement> = () => {
    // todo: use actual Telegram channel
    window.location.href = "tg://resolve?domain=vtxteam";
  };

  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        <T.Img
          style={{
            maxWidth: "100%",
            minHeight: "360px",
            aspectRatio: 1920 / 360,
          }}
          src={R.SupportBannerBackground}
        />
        <R.SupportBannerLogo
          style={{
            position: "absolute",
            width: "27.76%",
            height: "151.67%",
            inset: "-11.67% 17.5% auto auto",
          }}
        />
        <T.TopBar
          active="support"
        />
        <T.Typography
          style={{
            position: "absolute",
            inset: "50% auto auto 23.96%",
            fontWeight: 500,
            fontSize: "48px",
            lineHeight: "67px",
            color: theme.palette.light,
          }}
        >
          帮助与支持
        </T.Typography>
      </div>
      <T.Gap
        height={80}
      />
      <T.Typography
        style={{
          alignSelf: "center",
          fontWeight: 600,
          fontSize: "32px",
          lineHeight: "45px",
          color: theme.palette.dark,
        }}
      >
        常见问题
      </T.Typography>
      <T.Gap
        height={60}
      />
      <div
        style={{
          margin: "0 23.96%",
          display: "grid",
          gridTemplateColumns: "1fr",
        }}
      >
        <FaqItem
          title="Vortex 兼容哪些面板和协议？"
          description={"Vortex 完美兼容 V2board 和 SSPanel ，更可定制支持其他平台如 WHMCS 等。同时，这些主流面板的全部协议，如 Shadowsocks、Trojan、Vmess 以及新的协议如 SS-2022、Hysteria 等都可完美兼容，除此以外，Vortex 更可支持未来各面板可能会新增的 Reality、Tuic 等。"}
        />
        <T.Divider />
        <FaqItem
          title="我该如何选择用户数？"
          description={"Vortex 充分考虑不同用户对于定制客户端的使用需求和价格预期，因此我们创造性地使用用户数量和订阅周期作为客户端的授权指标，将原本遥不可及的定制客户端变得如此平易近人，也十分利好初创团队。Vortex 客户端的用户数，基于48小时周期内单独客户端的使用人数进行计算。因此，您可以充分预估每个平台的使用人数，购买不同平台的客户端。"}
        />
        <T.Divider />
        <FaqItem
          title="用户数不够用怎么办？"
          description={"Vortex 支持随时补差价升级，包括用户数和升级或者订阅周期的升级。"}
        />
        <T.Divider />
        <FaqItem
          title="客户端有哪些自定义的部分？"
          description={"Vortex 支持自由选择您自有品牌的应用图标，或者从我们提供的多套应用图标中挑选您中意的。同时，菜单栏/托盘图标您也可自由挑选。Vortex 也提供多套不同的主题，您亦可使用任何您想要的颜色作为您客户端的主题色。"}
        /><T.Divider />
        <FaqItem
          title="TUN 模式"
          description={"Vortex 全平台客户端均支持开启 TUN 模式，即代理所有的系统流量，而无需为应用再单独设置Socks等。"}
        />
        <T.Divider />
        <FaqItem
          title="服务端如何保证我的数据安全和隐私？"
          description={"本应用尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本应用会按照本隐私权政策的规定使用和披露您的个人信息。但本应用将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不时更新本隐私权政策。 您在同意本应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本应用服务使用协议不可分割的一部分。"}
        />
      </div>
      <T.Gap
        height={160}
      />
      <T.Typography
        style={{
          alignSelf: "center",
          fontWeight: 500,
          fontSize: "32px",
          lineHeight: "45px",
          color: theme.palette.black,
        }}
      >
        需要更多帮助？
      </T.Typography>
      <T.Gap
        height={80}
      />
      <div
        style={{
          padding: "0 23.96%",
          display: "grid",
          gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
        }}
      >
        <SupportItem
          icon={R.CustomerServiceIcon2}
          title="售前咨询"
          subtitle="09:00 至 18:00"
        />
        <SupportItem
          icon={R.MailIcon}
          title="客服邮箱"
          subtitle="sales@vtx.team"
          onClick={onMailClick}
        />
        <SupportItem
          icon={R.TelegramIcon}
          title="Telegram频道"
          subtitle="@vtxteam"
          onClick={onTelegramClick}
        />
      </div>
      <T.Gap
        height={260}
      />
      <T.BottomBar />
    </div>
  );
};

const FaqItem: React.FC<{
  title: string;
  description: string;
}> = (props) => {

  const arrowRef = useRef<SVGSVGElement>(null);

  const onOpening = () => {
    arrowRef.current!.style.transform = "matrix(1, 0, 0, -1, 0, 0)";
  };

  const onClosing = () => {
    arrowRef.current!.style.transform = "none";
  };

  return (
    <Collapsible
      // className={styles.collapsible}
      trigger={
        <div
          style={{
            height: "108px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <T.Typography
            style={{
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "25px",
              color: theme.palette.black,
              gap: "1px 0",
            }}
          >
            {props.title}
          </T.Typography>
          <R.ArrowIcon
            ref={arrowRef}
            style={{
            }}
          />
        </div>
      }
      transitionTime={150}
      onOpening={onOpening}
      onClosing={onClosing}
    >
      <T.Typography
        style={{
          display: "block",
          padding: "10px 0 48px",
          fontSize: "16px",
          lineHeight: "32px",
          color: theme.palette.blackMask(.5),
        }}
      >
        {props.description}
      </T.Typography>
    </Collapsible>
  );
};

const SupportItem: React.FC<{
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  subtitle: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}> = (props) => {

  const {
    icon: Icon,
    title,
    subtitle,
    onClick,
  } = props;

  return (
    <div
      style={{
        width: "280px",
        height: "64px",
        cursor: onClick ? "pointer" : "inherit",
        display: "flex",
        gap: "18px",
      }}
      onClick={onClick}
    >
      <Icon />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "4px",
        }}
      >
        <T.Typography
          style={{
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "25px",
            color: theme.palette.dark,
          }}
        >
          {title}
        </T.Typography>
        <T.Typography
          style={{
            fontSize: "16px",
            lineHeight: "22px",
            color: theme.palette.blackMask(.5),
          }}
        >
          {subtitle}
        </T.Typography>
      </div>
    </div>
  );
};

export default Support;

import { Button, ButtonProps } from "antd";
import classNames from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";
import theme from "~src/theme";

interface TButtonProps extends React.ComponentPropsWithRef<"button"> {
}

const TButton = React.forwardRef<HTMLButtonElement, TButtonProps>((props, ref) => {

  const {
    className,
    type = "button",
    ...restProps
  } = props;

  const styles = useStyles();

  return (
    <button
      ref={ref}
      className={classNames(styles.button, className)}
      type={type}
      {...restProps}
    />
  );
});

const useStyles = createUseStyles({
  button: {
    padding: "4px 8px",
    cursor: "pointer",
    borderRadius: "999px",
    border: `1px solid ${theme.palette.blackMask(.6)}`,
    boxShadow: `0 2px 8px ${theme.palette.blackMask(.08)}`,
    background: theme.palette.blackMask(.01),
    "&:hover": {
      background: theme.palette.blackMask(.025),
    },
    "&:active": {
      background: theme.palette.blackMask(.05),
    },
  },
});

interface TAntButtonProps extends ButtonProps {
}

const TAntButton = React.forwardRef<HTMLElement, TAntButtonProps>((props, ref) => {

  const {
    type = "primary",
    style,
    ...restProps
  } = props;

  return (
    <Button
      ref={ref}
      type={type}
      style={{
        borderRadius: "999px",
        ...style,
      }}
      {...restProps}
    />
  );
});

// export default TButton;
export default TAntButton;

import React from "react";
import Modal from "react-modal";
import theme from "~src/theme";

interface TModalProps extends Modal.Props {
}

const TModal = React.forwardRef<Modal, TModalProps>((props, ref) => {

  const {
    style: {
      overlay: overlayStyle,
      content: contentStyle,
    } = {},
    onRequestClose,
    ...restProps
  } = props;

  return (
    <Modal
      ref={ref}
      style={{
        overlay: {
          background: theme.palette.blackMask(.4),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...overlayStyle,
        },
        content: {
          position: "static",
          padding: 0,
          borderRadius: "16px",
          ...contentStyle,
        },
      }}
      onRequestClose={(event) => onRequestClose?.(event)}
      {...restProps}
    />
  );
});

export default TModal;
export type {
  TModalProps,
};

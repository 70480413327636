import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import bridge from "~src/backend/bridge";
import T from "~src/components";
import theme from "~src/theme";
import toolbox from "~src/toolbox";
import * as types from "~src/types";

const UserCoupon: React.FC = () => {

  const notificationRef = useRef<types.TNotificationInstance>(null);
  const discountInputRef = useRef<HTMLInputElement>(null);
  const countInputRef = useRef<HTMLInputElement>(null);

  const [ coupons, setCoupons ] = useState<types.Coupon[]>();

  const [ createCouponModalOpen, setCreateCouponModalOpen ] = useState<boolean>(false);

  const { t } = useTranslation();

  const loadCoupons = async () => {
    const r = await bridge.listCoupons();
    if (r?.status !== "OK") {
      notificationRef.current!.open("ERROR", "could not load coupon data");
      return;
    }
    r.data.map(_ => {
      _.created_at = toolbox.formatDate(_.created_at);
      return _;
    });
    setCoupons(r.data.sort((a, b) => b.id - a.id));
  };

  const onGenerateCouponClick = async () => {
    setCreateCouponModalOpen(true);
  };

  const onGenerate = async () => {
    // todo
    const discountString = discountInputRef.current!.value;
    const countString = countInputRef.current!.value;

    const discount = Number(discountString);
    const count = Number(countString);

    if (Number.isNaN(discount)) {
      notificationRef.current!.open("ERROR", "\"discount\" should be a number");
      return;
    }
    if (discount < 0 || discount > 1) {
      notificationRef.current!.open("ERROR", "\"discount\" should be in the range of 0 to 1");
      return;
    }
    if (Number.isNaN(discount)) {
      notificationRef.current!.open("ERROR", "\"count\" should be a number");
      return;
    }

    const r = await bridge.generateCoupon({
      discount,
      count,
    });
    if (r?.status !== "OK") {
      notificationRef.current!.open("ERROR", r?.data as string || "unknown");
      return;
    }

    notificationRef.current!.open("SUCCESS", `a coupon has been generated`);
    setCreateCouponModalOpen(false);
    await loadCoupons();
  };

  const onCancelClick = async () => {
    setCreateCouponModalOpen(false);
  };

  useEffect(() => {
    return toolbox.useAsyncEffectOnce(async () => {
      await loadCoupons();
    });
  }, []);

  const render: JSX.Element = (<>
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <T.Table
        dataSource={coupons}
        rowKey={(_) => _.id}
      >
        <T.Table.Column
          title={t("coupon_code")}
          dataIndex="code"
          key="id"
        />
        <T.Table.Column
          title={t("created_at")}
          dataIndex="created_at"
          key="id"
        />
        <T.Table.Column
          title={t("discount_rate")}
          dataIndex="discount"
          key="id"
        />
        <T.Table.Column
          title={t("commission_rate")}
          dataIndex="rate"
          key="id"
        />
        <T.Table.Column
          title={t("coupon_count")}
          dataIndex="count"
          key="id"
        />
        <T.Table.Column
          title={t("status")}
          dataIndex="status"
          key="id"
        />
      </T.Table>
      <div
        style={{
          position: "absolute",
          inset: "auto auto 8px 0",
          display: "flex",
          alignItems: "center",
        }}
      >
        <T.Button
          onClick={onGenerateCouponClick}
        >
          {t("generate_coupon")}
        </T.Button>
      </div>
    </div>
  </>);


  const renderModal: JSX.Element = (<>
    <T.Modal
      style={{
        content: {
          padding: "16px",
          display: "flex",
          flexDirection: "column",
        },
      }}
      isOpen={createCouponModalOpen}
      onRequestClose={() => onCancelClick()}
    >
      <T.Typography
        style={{
          fontSize: "24px",
          fontWeight: 600,
          color: theme.palette.dark,
          textAlign: "center",
        }}
      >
        {t("generate_coupon")}
      </T.Typography>
      <T.Gap
        height={32}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <T.Input
          ref={discountInputRef}
          placeholder={`${t("discount_rate")} (0.0 ~ 1.0)`}
        />
        <T.Input
          ref={countInputRef}
          placeholder={t("coupon_count")}
        />
      </div>
      <T.Gap
        height={24}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          gap: "16px",
        }}
      >
        <T.Button
          onClick={onGenerate}
        >
          {t("create")}
        </T.Button>
        <T.Button
          onClick={onCancelClick}
        >
          {t("cancel")}
        </T.Button>
      </div>
    </T.Modal>
  </>);

  const renderNotification: JSX.Element = (<>
    <T.Notification
      ref={notificationRef}
    />
  </>);

  return (<>
    {render}
    {renderNotification}
    {renderModal}
  </>);
};

export default UserCoupon;

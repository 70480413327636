import { Upload, UploadProps } from "antd";
import React from "react";

interface TAntUploadProps extends UploadProps {
}

const TAntUpload = React.forwardRef<any, TAntUploadProps>((props, ref) => {

  const {
    ...restProps
  } = props;

  return (
    <Upload
      ref={ref}
      {...restProps}
    />
  );
});

export default TAntUpload;


const backendUrl = "https://vtx.team/-";
const tokenPayUrl = "https://pay.vtx.team";
const customerServiceUrl = "https://t.me/vtxteam";
const priceModifier = 1;

const config = {
  backendUrl,
  tokenPayUrl,
  customerServiceUrl,
  priceModifier,
};

export default config;

import React, { MouseEventHandler } from "react";
import T from "~src/components/index";
import R from "~src/res";
import theme from "~src/theme";
import toolbox from "~src/toolbox";

interface TopBarProps {
  active: Page;
}

const TopBar = React.forwardRef<HTMLDivElement, TopBarProps>((props, ref) => {

  const {
    active,
  } = props;

  const navigate = toolbox.useNavigate();

  const onHomePageClick: MouseEventHandler = (event) => {
    navigate("/");
  };

  const onPurchaseClick: MouseEventHandler<HTMLDivElement> = (event) => {
    navigate("/purchase");
  };

  const onSupportClick: MouseEventHandler<HTMLDivElement> = (event) => {
    navigate("/support");
  };

  const onAccountClick: MouseEventHandler<SVGSVGElement> = (event) => {
    navigate("/login");
  };

  return (
    <div
      ref={ref}
      style={{
        position: "absolute",
        inset: "0 0 auto",
        padding: "0 16.67%",
        height: "84px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        backgroundColor: theme.palette.whiteMask(.3),
        backdropFilter: "blur(25px)",
      }}
    >
      <R.Logo
        style={{
          cursor: "pointer",
        }}
        onClick={onHomePageClick}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "100px",
        }}
      >
        <T.Typography
          style={{
            fontWeight: active === "home" ? 500 : 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: theme.palette.black,
          }}
          hover
          onClick={onHomePageClick}
        >
          首页
        </T.Typography>
        <T.Typography
          style={{
            fontWeight: active === "purchase" ? 500 : 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: theme.palette.black,
          }}
          hover
          onClick={onPurchaseClick}
        >
          购买
        </T.Typography>
        <T.Typography
          style={{
            fontWeight: active === "support" ? 500 : 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: theme.palette.black,
          }}
          hover
          onClick={onSupportClick}
        >
          支持
        </T.Typography>
        <T.Icon
          icon={R.AccountIcon}
          width={24}
          height={24}
          fill={theme.palette.black}
          hover
          onClick={onAccountClick}
        />
      </div>
    </div>
  );
});

type Page = "home" | "purchase" | "support";

export default TopBar;

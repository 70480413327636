import { configureStore, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import Plan from "~src/backend/Plan";
import * as types from "~src/types";

const plan = Plan;

interface ActivePlanState {
  platform: types.PlanItemInterface;
  user: types.PlanItemInterface;
  period: types.PlanItemInterface;
}

const activePlanSlice = createSlice({
  name: 'activePlan',
  initialState: {
    platform: plan.Platforms[0],
    user: plan.Users[0],
    period: plan.Periods[0],
  } satisfies ActivePlanState,
  reducers: {
    setPlatform: (state, action: PayloadAction<types.PlanItemInterface>) => {
      state.platform = action.payload;
    },
    setUser: (state, action: PayloadAction<types.PlanItemInterface>) => {
      state.user = action.payload;
    },
    setPeriod: (state, action: PayloadAction<types.PlanItemInterface>) => {
      state.period = action.payload;
    },
  },
});

const PurchaseStore = configureStore({
  reducer: {
    activePlan: activePlanSlice.reducer,
  },
});

const activePlanReducerMap: {[_ in keyof ActivePlanState]: keyof typeof activePlanSlice.actions} = {
  "platform": "setPlatform",
  "user": "setUser",
  "period": "setPeriod",
};

const useActivePlanDispatch: () => typeof PurchaseStore.dispatch = useDispatch;
const useActivePlanSelector: TypedUseSelectorHook<ReturnType<typeof PurchaseStore.getState>> = useSelector;

export default {
  PurchaseStore,
  activePlanReducerMap,
  activePlanSlice,
  useActivePlanDispatch,
  useActivePlanSelector,
};
export type {
  ActivePlanState,
};

import bridge from "~src/backend/bridge";
import Plan from "~src/backend/Plan";
import redux from "~src/backend/redux";

const backend = {
  Plan,
  bridge,
  redux,
};

export default backend;
export {
  backend,
};

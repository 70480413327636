import React, { MouseEventHandler, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import backend from "~src/backend";
import T from "~src/components";
import R from "~src/res";
import theme from "~src/theme";
import toolbox from "~src/toolbox";
import * as types from "~src/types";

const bridge = backend.bridge;

const Login: React.FC = () => {

  const notificationRef = useRef<types.TNotificationInstance>(null);
  const usernameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const navigate = toolbox.useNavigate();
  const { t } = useTranslation();

  const onLoginSuccess = () => {
    navigate("/user/app", { replace: true });
  };

  const onLoginFailure = (message: string) => {
    notificationRef.current!.open("ERROR", message);
  };

  const onLoginClick: MouseEventHandler<HTMLElement> = async () => {
    const username = usernameInputRef.current!.value;
    const password = passwordInputRef.current!.value;
    if (username === "" || password === "") {
      onLoginFailure("Both username and password can't be blank. ");
      return;
    }
    const r = await bridge.login({ username, password });
    if (r?.status === "OK") {
      onLoginSuccess();
    } else {
      onLoginFailure(r?.data || "unknown");
    }
  };

  const onRegisterClick: MouseEventHandler<HTMLElement> = async () => {
    navigate("/register");
  };

  useEffect(() => {
    return toolbox.useEffectOnce(() => {
      bridge.isOnline().then((r) => {
        if (r?.status === "OK") {
          navigate("/user/app", { replace: true });
        }
      });
    });
  }, []);

  const renderNotification: JSX.Element = (<>
    <T.Notification
      ref={notificationRef}
    />
  </>);

  const render: JSX.Element = (<>
    <div
      style={{
        position: "relative",
        width: "100vw",
        minHeight: "100vh",
        background: theme.palette.blackMask(.1),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          padding: "48px",
          background: theme.palette.whiteMask(.8),
          borderRadius: "16px",
          boxShadow: `0 8px 32px ${theme.palette.blackMask(.05)}`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <T.Typography
          style={{
            alignSelf: "center",
            fontSize: "32px",
            color: theme.palette.dark,
          }}
        >
          Vortex
        </T.Typography>
        <T.Gap
          height={32}
        />
        <T.Input
          ref={usernameInputRef}
          placeholder={t("username")}
        />
        <T.Gap
          height={16}
        />
        <T.Input
          ref={passwordInputRef}
          type="password"
          placeholder={t("password")}
        />
        <T.Gap
          height={48}
        />
        <div
          style={{
            display: "grid",
            gridAutoRows: "32px",
            gap: "16px",
          }}
        >
          <T.Button
            onClick={onLoginClick}
          >
            {t("sign_in")}
          </T.Button>
          <T.Button
            type={"link"}
            onClick={onRegisterClick}
          >
            {t("registration")}
          </T.Button>
        </div>
      </div>
    </div>
  </>);

  return (<>
    {render}
    {renderNotification}
  </>);
};

export default Login;

import React, { RefObject, useEffect, useImperativeHandle, useRef, useState } from "react";
import Modal from "react-modal";

interface TPopoverProps extends Omit<Modal.Props, "isOpen"> {
  anchor?: RefObject<Element>;
  position?: TPopoverPosition;
}

const TPopover = React.forwardRef<TPopoverInstance, TPopoverProps>((props, ref) => {

  const {
    children,
    style: {
      overlay,
      content,
    } = {},
    onRequestClose,
    anchor,
    position = "Default",
    ...restProps
  } = props;

  const modalRef = useRef<Modal>(null);
  const contentRef = useRef<HTMLDivElement>();
  const timerRef = useRef<number>();

  const [ open, setOpen ] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    open: (_, duration = undefined) => {
      const old = open;
      if (timerRef.current !== undefined) {
        clearTimeout(timerRef.current);
        timerRef.current = undefined;
      }
      setOpen(_);
      if (duration) {
        timerRef.current = window.setTimeout(() => {
          setOpen(old);
          timerRef.current = undefined;
        }, duration);
      }
      return old;
    },
  }));

  const inset: React.CSSProperties["inset"] = (() => {
    if (anchor?.current === undefined) {
      return undefined;
    }
    if (anchor.current === null) {
      switch (position) {
        case "BottomRight": {
          return `auto auto 32px 32px`;
        }
        default: {
          return undefined;
        }
      }
    }
    const domRect = anchor.current!.getBoundingClientRect();
    switch (position) {
      case "BottomLeft": {
        return `${domRect.bottom + 8}px auto auto ${domRect.left}px`;
      }
      case "BottomRight": {
        return `${domRect.bottom + 8}px ${document.documentElement.clientWidth - domRect.right}px auto auto`;
      }
      default: {
        return undefined;
      }
    }
  })();

  const _onRequestClose: Modal.Props["onRequestClose"] = (event) => {
    onRequestClose?.(event);
    setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      if (open) {
        contentRef.current!.style.opacity = open ? "1" : "0";
      }
    }, 0);
  }, [
    open,
  ]);

  return (
    <Modal
      ref={modalRef}
      contentRef={(_) => (contentRef.current = _)}
      isOpen={open}
      style={{
        overlay: {
          background: "transparent",
          ...overlay,
        },
        content: {
          inset: inset,
          padding: 0,
          background: "transparent",
          border: "none",
          opacity: 0,
          transition: "opacity .2s",
          ...content,
        },
      }}
      onRequestClose={_onRequestClose}
      {...restProps}
    >
      {children}
    </Modal>
  );
});

type TPopoverPosition = "BottomLeft" | "BottomRight";

interface TPopoverInstance {
  open: (_: boolean, duration?: number) => boolean;
}

export default TPopover;
export type {
  TPopoverInstance,
};

import classNames from "classnames";
import React, { HTMLAttributes } from "react";
import { createUseStyles } from "react-jss";

interface TTypographyProps extends HTMLAttributes<HTMLSpanElement> {
  hover?: boolean;
}

const TTypography = React.forwardRef<HTMLSpanElement, TTypographyProps>((props, ref) => {

  let {
    className,
    hover = false,
    style = {},
    ...restProps
  } = props;

  const styles = useStyles();

  style = {
    fontFamily: "PingFang SC, system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
    userSelect: "none",
    ...style,
  } satisfies React.CSSProperties;

  // todo: remove this line on release
  if (style.color === undefined) {
    throw new Error("[TTypography] ERROR: colour not specified. ");
  }

  return (
    <span
      ref={ref}
      className={classNames(
        {[styles.clickable]: props.onClick},
        {[styles.hover]: hover},
        className,
      )}
      style={style}
      {...restProps}
    />
  );
});

const useStyles = createUseStyles({
  "clickable": {
    cursor: "pointer",
  },
  "hover": {
    opacity: .6,
    "&:hover": {
      opacity: 1,
    },
  },
});

export default TTypography;

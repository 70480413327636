import { Dropdown, DropdownProps } from "antd";
import React from "react";

interface TDropdownProps extends DropdownProps {
}

const TAntDropdown: React.FC<DropdownProps> = (props) => {

  const {
    ...restProps
  } = props;

  return (
    <Dropdown
      {...restProps}
    />
  );
};

export default TAntDropdown;
export type {
  TDropdownProps,
};

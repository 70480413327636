import i18next from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      // common
      "parentheses": " ({{content}})",
      "yes": "Yes",
      "no": "No",
      // purchase
      "order_configuration": "Order Configuration",
      "product": "Product",
      "discount": "Discount",
      "platforms": "Platforms",
      "confirm": "Confirm",
      "application_name": "Application Name",
      "unchangeable_after_created": "** UNCHANGEABLE **",
      // top level
      "registration": "Registration",
      "old_password": "Old password",
      "new_password": "New password",
      "password2": "Repeat password",
      "register": "Register",
      "sign_in": "Sign in",
      // menu
      "order": "Order",
      "app": "App",
      "task": "Task",
      "agent": "Agent",
      "coupon": "Coupon",
      "change_password": "Change password",
      "sign_out": "Sign out",
      // order
      "renew": "Renew",
      "upgrade": "Upgrade",
      "options": "Options",
      "residual": "Residual",
      "payable": "Payable",
      "expire_at": "Expire at",
      "optional": " (Optional)",
      "status": "Status",
      "name": "Name",
      "platform": "Platform",
      "price": "Price",
      "testing_build": "Testing",
      "created_at": "Created at",
      "paid_at": "Paid at",
      "action": "Action",
      "pay": "Pay",
      "server_type": "Server Type",
      "one_per_line": " (one per line)",
      "sub_flag": "Subscription Type",
      "urls": "API URLs",
      "oss_hosting_url": "OSS Hosting URL",
      "oss_hosting_url_placeholder": "ends with \".json\"",
      "built_in_proxy": "Built-in Proxy",
      "homepage_url": "Homepage URL",
      "support_api": "Customer Service URL",
      "telegram_group": "Telegram URL",
      "terms_of_service": "Terms of Service URL",
      "package_name": "Package Name",
      "for_testing_build_only": "For Testing Build Only",
      "colour": "Colour",
      "icon": "Icon",
      "in_app_icon": "In-App Icon",
      "banner": "Banner",
      "choose_file": "Choose File",
      "remove_file": "Remove File",
      "tray_icon": "Tray Icon",
      "save": "Save",
      "save_and_build": "Save & Build",
      "save_and_build_testing": "Save & Build Testing",
      "export_oss_json": "Export OSS JSON string",
      "version": "Version",
      "username": "Username",
      "password": "Password",
      "rate": "Rate",
      "create_agent_account": "Create Agent Account",
      "create": "Create",
      "cancel": "Cancel",
      "coupon_code": "Code",
      "discount_rate": "Discount",
      "commission_rate": "Commission",
      "coupon_count": "Count",
      "generate_coupon": "Generate Coupon",
      // backend
      "order_pending": "Pending",
      "order_paid": "Paid",
      "order_cancelled": "Cancelled",
      "task_pending": "Pending",
      "task_started": "Started",
      "task_failed": "Failed",
      "task_finished": "Finished",
    },
  },
  cn: {
    translation: {
      // common
      "parentheses": "（{{content}}）",
      "yes": "是",
      "no": "否",
      // purchase
      "order_configuration": "订单配置",
      "product": "产品",
      "discount": "折扣",
      "platforms": "平台",
      "confirm": "确认",
      "application_name": "应用名称",
      "unchangeable_after_created": "** 一经设定，不可更改 **",
      // top level
      "registration": "新用户注册",
      "old_password": "原密码",
      "new_password": "新密码",
      "password2": "重复输入密码",
      "register": "注册",
      "sign_in": "登录",
      // menu
      "order": "订单",
      "app": "App",
      "task": "任务",
      "agent": "代理",
      "coupon": "优惠券",
      "change_password": "修改密码",
      "sign_out": "登出",
      // frontend
      "renew": "续订",
      "upgrade": "升级",
      "options": "选项",
      "residual": "折现",
      "payable": "应付金额",
      "expire_at": "有效期至",
      "optional": "（可选）",
      "status": "状态",
      "name": "名称",
      "platform": "平台",
      "price": "价格",
      "testing_build": "测试版",
      "created_at": "创建于",
      "paid_at": "支付于",
      "action": "操作",
      "pay": "支付",
      "server_type": "服务端类型",
      "one_per_line": "（一行一个）",
      "sub_flag": "订阅类型",
      "urls": "API地址",
      "oss_hosting_url": "OSS地址",
      "oss_hosting_url_placeholder": "以.json结尾",
      "built_in_proxy": "内建代理",
      "homepage_url": "首页地址",
      "support_api": "客服地址",
      "telegram_group": "Telegram链接",
      "terms_of_service": "使用条款链接",
      "package_name": "包名",
      "for_testing_build_only": "仅限测试版",
      "colour": "颜色",
      "icon": "图标",
      "in_app_icon": "应用程序内图标",
      "banner": "登录页图片",
      "choose_file": "选择文件",
      "remove_file": "移除文件",
      "tray_icon": "托盘图标",
      "save": "保存",
      "save_and_build": "保存并打包",
      "save_and_build_testing": "保存并打包（测试版）",
      "export_oss_json": "导出OSS JSON文本",
      "version": "版本",
      "username": "用户名",
      "password": "密码",
      "rate": "比率",
      "create_agent_account": "创建代理帐号",
      "create": "创建",
      "cancel": "取消",
      "coupon_code": "优惠码",
      "discount_rate": "折扣率",
      "commission_rate": "佣金率",
      "coupon_count": "剩余次数",
      "generate_coupon": "生成优惠码",
      // backend
      "order_pending": "待支付",
      "order_paid": "已支付",
      "order_cancelled": "已取消",
      "task_pending": "排队中",
      "task_started": "打包中",
      "task_failed": "失败",
      "task_finished": "已完成",
    },
  },
};

const translation = i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: "cn",
    interpolation: {
      escapeValue: false,
    },
  });

export default translation;

import React from "react";
import { TabPanel, TabPanelProps } from "react-tabs";

interface TTabPanelProps extends TabPanelProps {
}

const TTabPanel = React.forwardRef<HTMLDivElement, TTabPanelProps>((props, ref) => {
  return (
    <TabPanel
      ref={ref}
      {...props}
    />
  );
});

export default TTabPanel;
export type {
  TTabPanelProps,
};

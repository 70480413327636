import React, { MouseEventHandler, useRef } from "react";
import { createUseStyles } from "react-jss";
import R from "~src/res";
import T from "~src/components";
import theme from "~src/theme";
import toolbox from "~src/toolbox";

const Home: React.FC = () => {

  const bannerTextContainerRef = useRef<HTMLDivElement>(null);

  const navigate = toolbox.useNavigate();

  const styles = useStyles();

  const onBannerVideoEnded = () => {
    const bannerTextContainerStyle = bannerTextContainerRef.current!.style;
    bannerTextContainerStyle.transform = "none";
    bannerTextContainerStyle.opacity = "1";
  };

  const onPurchaseClick: MouseEventHandler<HTMLButtonElement> = () => {
    navigate("/purchase");
  };

  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          position: "relative",
        }}
      >
        {/*<T.Img*/}
        {/*  style={{*/}
        {/*    width: "100%",*/}
        {/*    aspectRatio: 1920 / 720,*/}
        {/*  }}*/}
        {/*  src={R.HomeBanner}*/}
        {/*/>*/}
        <T.Player
          style={{
            aspectRatio: 1920 / 720,
          }}
          width={"100%"}
          height={"auto"}
          url={R.HomeBannerVideo}
          playing
          muted
          onEnded={onBannerVideoEnded}
        />
        <T.TopBar
          active="home"
        />
        <div
          ref={bannerTextContainerRef}
          style={{
            position: "absolute",
            inset: "54.72% 0 auto",
            transform: "translateY(-34px)",
            opacity: 0,
            transition: "transform .47s cubic-bezier(0,.56,.46,1), opacity .47s cubic-bezier(0,.56,.46,1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <T.Typography
            style={{
              fontWeight: 500,
              fontSize: "48px",
              lineHeight: "67px",
              color: theme.palette.light,
            }}
          >
            下一代自由网络平台
          </T.Typography>
          <T.Typography
            style={{
              fontWeight: 400,
              fontSize: "24px",
              lineHeight: "34px",
              color: theme.palette.light,
            }}
          >
            全平台全链路，自由定义未来
          </T.Typography>
        </div>
      </div>
      <div
        style={{
          padding: "80px 0 0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <T.Typography
          style={{
            fontWeight: 600,
            fontSize: "32px",
            lineHeight: "45px",
            color: theme.palette.dark,
          }}
        >
          全平台，不设限
        </T.Typography>
        <T.Gap
          height={8}
        />
        <T.Typography
          style={{
            fontSize: "16px",
            lineHeight: "22px",
            color: theme.palette.blackMask(.5),
          }}
        >
          兼容主流系统平台，多端设备体验无间。
        </T.Typography>
        <T.Gap
          height={36}
        />
        <div
          style={{
            opacity: .5,
            display: "flex",
            alignItems: "center",
            gap: "32px",
          }}
        >
          <T.Icon
            icon={R.MacOSIcon}
          />
          <T.Icon
            icon={R.WindowsIcon}
          />
          <T.Icon
            icon={R.AndroidIcon}
          />
          <T.Icon
            icon={R.IOSIcon}
          />
        </div>
        <T.Gap
          height={77}
        />
        <T.Img
          width={960}
          height={480}
          src={R.PlatformImage}
        />
      </div>
      <T.Gap
        height={48}
      />
      <div
        style={{
          alignSelf: "center",
          width: "1280px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "480px",
            borderRadius: "16px",
            backgroundColor: theme.palette.neutral[2],
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              position: "relative",
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <T.Img
              style={{
                position: "absolute",
                inset: "44px auto auto -29px",
              }}
              width={114}
              height={114}
              src={R.Cube2Image}
            />
            <T.Img
              style={{
                position: "absolute",
                inset: "191px auto auto 495px",
              }}
              width={114}
              height={114}
              src={R.Cube1Image}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <T.Typography
                style={{
                  fontWeight: 600,
                  fontSize: "32px",
                  lineHeight: "45px",
                  color: theme.palette.dark,
                }}
              >
                随心定制，自由搭配
              </T.Typography>
              <T.Typography
                style={{
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: theme.palette.blackMask(.5),
                }}
              >
                全新架构带来超高自由度，简单几步即可定制专属品牌。
              </T.Typography>
            </div>
          </div>
          <T.Img
            width={680}
            height={480}
            src={R.CustomisationImage}
          />
        </div>
        <T.Gap
          height={40}
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
            gridAutoRows: "400px",
            gap: "40px",
          }}
        >
          <div
            style={{
              position: "relative",
              padding: "32px",
              borderRadius: "16px",
              background: `url(${R.ThemesImage}) right 0 bottom 0 / 420px 326px no-repeat, ${theme.palette.neutral[2]}`,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <T.Typography
                style={{
                  background: "linear-gradient(270deg, #1AC8FF 2.73%, #1AFF75 53.9%, #FF881A 100%)",
                  backgroundClip: "text",
                  // @ts-ignore
                  webkitBackgroundClip: "text",
                  fontWeight: 600,
                  fontSize: "32px",
                  lineHeight: "45px",
                  color: "transparent",
                }}
              >
                主题色彩
              </T.Typography>
              <T.Typography
                style={{
                  color: theme.palette.blackMask(.5),
                }}
              >
                内置不同主题，更可自由定制品牌专属色彩。
              </T.Typography>
            </div>
          </div>
          <div
            style={{
              position: "relative",
              padding: "32px",
              borderRadius: "16px",
              background: theme.palette.neutral[2],
              overflow: "hidden",
            }}
          >
            {renderBackgroundSquares}
            <div
              style={{
                position: "absolute",
                inset: "196px auto auto 218px",
                width: "96px",
                height: "96px",
                background: `url(${R.AppIcon2BackgroundImage}) center / 100% no-repeat, linear-gradient(136.71deg, #737373 0%, #454545 97.18%)`,
                backgroundBlendMode: "multiply",
                boxShadow: "0px 8px 24px 2px rgba(60, 63, 69, 0.15)",
                borderRadius: "21.33px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <R.AppIcon2 />
            </div>
            <div
              style={{
                position: "absolute",
                inset: "130px auto auto 283px",
                width: "96px",
                height: "96px",
                background: "linear-gradient(136.71deg, rgba(95, 197, 255, 0.8) 0%, rgba(64, 169, 255, 0.8) 97.18%)",
                boxShadow: "0px 8px 24px 2px rgba(69, 174, 255, 0.15)",
                backdropFilter: "blur(6px)",
                borderRadius: "21.33px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <R.AppIcon
                style={{
                  margin: "28px 0 0",
                }}
              />
            </div>
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <T.Typography
                style={{
                  fontWeight: 600,
                  fontSize: "32px",
                  lineHeight: "45px",
                  color: theme.palette.dark,
                }}
              >
                多样图标
              </T.Typography>
              <T.Typography
                style={{
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: theme.palette.blackMask(.5),
                }}
              >
                多套应用图标和托盘图标，亦可自由更换品牌专属图标。
              </T.Typography>
            </div>
          </div>
        </div>
        <T.Gap
          height={100}
        />
      </div>
      <div
        style={{
          // fixme: colour not in theme
          background: "#1B232C",
        }}
      >
        <T.Gap
          height={80}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <T.Typography
            style={{
              fontWeight: 600,
              fontSize: "32px",
              lineHeight: "45px",
              color: theme.palette.light,
            }}
          >
            可能是史上最好用的定制客户端
          </T.Typography>
          <T.Typography
            style={{
              fontSize: "16px",
              lineHeight: "22px",
              color: theme.palette.whiteMask(.5),
            }}
          >
            更丰富更实用的功能，更简洁更易用的操作
          </T.Typography>
        </div>
        <T.Gap
          height={120}
        />
        <div
          style={{
            margin: "0 19.17%",
            display: "grid",
            justifyContent: "space-between",
            gridTemplateColumns: "repeat(3, min-content)",
            gap: "100px",
          }}
        >
          <Feature
            icon={R.TunIcon}
            title="TUN 模式"
            subtitle="全平台虚拟网络设备功能"
          />
          <Feature
            icon={R.OssIcon}
            title="OSS 防被墙"
            subtitle="多重网络安全服务防护"
          />
          <Feature
            icon={R.GroupIcon}
            title="策略组"
            subtitle="强大的分流策略定制"
          />
          <Feature
            icon={R.ProtocolIcon}
            title="全协议支持"
            subtitle="支持 SS-2022、Hysteria 等新一代协议"
          />
          <Feature
            icon={R.DashboardIcon}
            title="Dashboard"
            subtitle="简洁易用的网络监控信息"
          />
          <Feature
            icon={R.PanelsIcon}
            title="多面板适配"
            subtitle="完美支持V2board、SSPanel，可定制WHMCS等"
          />
        </div>
        <T.Gap
          height={180}
        />
      </div>
      <Feature2
        background={`url(${R.PurchaseSystemIconRaw}) bottom 0 right calc(16.67% + 630px * .1667) no-repeat, ${theme.palette.light}`}
        icon={R.BuyIcon}
        title="内购系统"
        subtitle="全套订单管理功能，支持客户端内直接下单和支付。"
      />
      <Feature2
        // todo: colour not in theme
        background={`url(${R.CustomerServiceSystemIconRaw}) bottom 0 right calc(16.67% + 630px * .1667) no-repeat, #F0F9FF`}
        icon={R.CustomerServiceIcon}
        title="客服组件"
        subtitle="附带即时聊天功能，支持文字及图片对话，可定制客服管理功能。"
      />
      <div
        style={{
          aspectRatio: 7680 / 3560,
          background: `url(${R.UiShowImage}) center / contain`,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <R.Logo />
        <T.Gap
          height={12}
        />
        <T.Typography
          style={{
            fontSize: "24px",
            lineHeight: "34px",
            color: theme.palette.dark,
          }}
        >
          下一代自由网络平台
        </T.Typography>
        <T.Gap
          height={30}
        />
        <button
          className={styles.purchaseButton}
          type="button"
          onClick={onPurchaseClick}
        >
          <T.Typography
            style={{
              fontSize: "16px",
              lineHeight: "22px",
              color: theme.palette.light,
            }}
          >
            立即订购
          </T.Typography>
        </button>
      </div>
      <T.BottomBar />
    </div>
  );
};

const Feature: React.FC<{
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  subtitle: string;
}> = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "18px",
      }}
    >
      <props.icon />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <T.Typography
          style={{
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "25px",
            color: theme.palette.light,
          }}
        >
          {props.title}
        </T.Typography>
        <T.Typography
          style={{
            fontSize: "16px",
            lineHeight: "22px",
            color: theme.palette.whiteMask(.5),
            whiteSpace: "nowrap",
          }}
        >
          {props.subtitle}
        </T.Typography>
      </div>
    </div>
  );
};

const Feature2: React.FC<{
  background: React.CSSProperties['background'];
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  subtitle: string;
}> = (props) => {
  return (
    <div
      style={{
        position: "relative",
        height: "420px",
        background: props.background,
      }}
    >
      <div
        style={{
          position: "absolute",
          inset: "101px auto auto 16.67%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <props.icon />
        <T.Typography
          style={{
            fontWeight: 600,
            fontSize: "32px",
            lineHeight: "45px",
            color: theme.palette.dark,
          }}
        >
          {props.title}
        </T.Typography>
        <T.Typography
          style={{
            fontSize: "16px",
            lineHeight: "22px",
            color: theme.palette.blackMask(.5),
          }}
        >
          {props.subtitle}
        </T.Typography>
      </div>
    </div>
  );
};

const renderBackgroundSquares: JSX.Element = (<>
  <div
    style={{
      position: "absolute",
      inset: "148px auto auto 152px",
      width: "56px",
      height: "56px",
      // fixme: colours not in theme
      background: "linear-gradient(136.71deg, rgba(241, 241, 241, 0.8) 0%, rgba(210, 210, 210, 0.8) 97.18%)",
      opacity: .5,
      backdropFilter: "blur(3.5px)",
      borderRadius: "12.5px",
    }}
  />
  <div
    style={{
      position: "absolute",
      inset: "78px auto auto 438px",
      width: "52px",
      height: "52px",
      // fixme: colours not in theme
      background: "linear-gradient(136.71deg, rgba(241, 241, 241, 0.8) 0%, rgba(210, 210, 210, 0.8) 97.18%)",
      opacity: .5,
      backdropFilter: "blur(3.5px)",
      borderRadius: "12.5px",
    }}
  />
  <div
    style={{
      position: "absolute",
      inset: "262px auto auto 379px",
      width: "74px",
      height: "74px",
      // fixme: colours not in theme
      background: "linear-gradient(136.71deg, rgba(255, 255, 255, 0.5) 0%, rgba(210, 210, 210, 0.5) 97.18%)",
      backdropFilter: "blur(10px)",
      borderRadius: "16px",
    }}
  />
  <div
    style={{
      position: "absolute",
      inset: "315px auto auto -34px",
      width: "154px",
      height: "154px",
      // fixme: colours not in theme
      background: "linear-gradient(136.71deg, rgba(255, 255, 255, 0.5) 0%, rgba(210, 210, 210, 0.5) 97.18%)",
      backdropFilter: "blur(10px)",
      borderRadius: "40px",
    }}
  />
  <div
    style={{
      position: "absolute",
      inset: "149px auto auto 565px",
      width: "72px",
      height: "72px",
      // fixme: colours not in theme
      background: "linear-gradient(136.71deg, rgba(255, 255, 255, 0.5) 0%, rgba(210, 210, 210, 0.5) 97.18%)",
      backdropFilter: "blur(10px)",
      borderRadius: "14px",
    }}
  />
</>);

const useStyles = createUseStyles({
  purchaseButton: {
    minWidth: "192px",
    height: "48px",
    border: "none",
    borderRadius: "999px",
    background: theme.palette.dark,
    boxShadow: "0px 2px 12px 2px rgba(0, 0, 0, 0.15), 0px 14px 28px 3px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.dark2,
    },
    "&:active": {
      background: theme.palette.dark2,
    },
  },
});

export default Home;

import { Input } from "antd";
import { TextAreaProps } from "antd/es/input";
import { TextAreaRef } from "antd/es/input/TextArea";
import React from "react";
import toolbox from "~src/toolbox";

interface TTextAreaProps extends TextAreaProps {
}

const TAntTextArea = React.forwardRef<HTMLTextAreaElement, TTextAreaProps>((props, ref) => {

  const {
    ...restProps
  } = props;

  return (
    <Input.TextArea
      ref={(_) => {
        if (_) toolbox.reactPropagateRef(ref, _.resizableTextArea!.textArea);
      }}
      {...restProps}
    />
  );
});

export default TAntTextArea;

import React from "react";
import T from "~src/components/index";
import theme from "~src/theme";

interface BottomBarProps {
}

const BottomBar = React.forwardRef<HTMLDivElement, BottomBarProps>((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        padding: "0 16.67%",
        height: "96px",
        background: theme.palette.neutral[2],
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <T.Typography
        style={{
          fontSize: "12px",
          lineHeight: "17px",
          color: theme.palette.blackMask(.3),
        }}
      >
        Copyright © 2023 Vortex. All rights reserved.
      </T.Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "42px",
        }}
      >
        <T.Typography
          style={{
            fontSize: "12px",
            lineHeight: "17px",
            color: theme.palette.blackMask(.5),
          }}
        >
          联系我们
        </T.Typography>
        <T.Typography
          style={{
            fontSize: "12px",
            lineHeight: "17px",
            color: theme.palette.blackMask(.5),
          }}
        >
          帮助支持
        </T.Typography>
        <T.Typography
          style={{
            fontSize: "12px",
            lineHeight: "17px",
            color: theme.palette.blackMask(.5),
          }}
        >
          法律声明
        </T.Typography>
      </div>
    </div>
  );
});

export default BottomBar;

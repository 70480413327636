import React from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";

interface TPlayerProps extends ReactPlayerProps {
}

const TPlayer = React.forwardRef<ReactPlayer, TPlayerProps>((props, ref) => {
  return (
    <ReactPlayer
      ref={ref}
      {...props}
    />
  );
});

export default TPlayer;

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import bridge from "~src/backend/bridge";
import T from "~src/components";
import theme from "~src/theme";
import toolbox from "~src/toolbox";
import * as types from "~src/types";

const UserAgent: React.FC = () => {

  const notificationRef = useRef<types.TNotificationInstance>(null);
  const usernameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const rateInputRef = useRef<HTMLInputElement>(null);

  const [ agents, setAgents ] = useState<types.Agent[]>();

  const [ createAgentModalOpen, setCreateAgentModalOpen ] = useState<boolean>(false);

  const { t } = useTranslation();

  const loadAgents = async () => {
    const r = await bridge.listAgents();
    if (r?.status !== "OK") {
      notificationRef.current!.open("ERROR", r?.data as string || "unknown");
      return;
    }
    setAgents(r!.data as types.Agent[]);
  };

  const onCreateAgentAccountClick = async () => {
    setCreateAgentModalOpen(true);
  };

  const onCreateClick = async () => {
    const username = usernameInputRef.current!.value;
    const password = passwordInputRef.current!.value;
    const rateString = rateInputRef.current!.value;

    const rate = Number(rateString);
    if (Number.isNaN(rate)) {
      notificationRef.current!.open("ERROR", "The field \"rate\" does not have a valid number");
      return;
    } else if (rate < 0 || rate > 1) {
      notificationRef.current!.open("ERROR", "Invalid rate; the range of the rate should always be 0~1");
      return;
    }

    const r = await bridge.register({
      username,
      password,
      downline: true,
      rate,
    });
    if (r?.status !== "OK") {
      notificationRef.current!.open("ERROR", r?.data || "unknown");
      return;
    }
    notificationRef.current!.open("SUCCESS", "Agent account has been created");
    setCreateAgentModalOpen(false);
    await loadAgents();
  };

  const onCancelClick = async () => {
    setCreateAgentModalOpen(false);
  };

  useEffect(() => {
    return toolbox.useAsyncEffectOnce(async () => {
      await loadAgents();
    });
  }, []);

  const render: JSX.Element = (<>
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <T.Table
        dataSource={agents}
        rowKey={(_) => _.username}
      >
        <T.Table.Column
          title={t("username")}
          dataIndex="username"
          key="username"
        />
        <T.Table.Column
          title={t("rate")}
          dataIndex="rate"
          key="rate"
        />
      </T.Table>
      <div
        style={{
          position: "absolute",
          inset: "auto auto 8px 0",
          display: "flex",
          alignItems: "center",
        }}
      >
        <T.Button
          onClick={onCreateAgentAccountClick}
        >
          {t("create_agent_account")}
        </T.Button>
      </div>
    </div>
  </>);

  const renderModal: JSX.Element = (<>
    <T.Modal
      style={{
        overlay: {
          zIndex: theme.zIndex.modal,
        },
        content: {
          padding: "16px",
          display: "flex",
          flexDirection: "column",
        },
      }}
      isOpen={createAgentModalOpen}
      onRequestClose={() => onCancelClick()}
    >
      <T.Typography
        style={{
          fontSize: "24px",
          fontWeight: 600,
          color: theme.palette.dark,
          textAlign: "center",
        }}
      >
        {t("create_agent_account")}
      </T.Typography>
      <T.Gap
        height={32}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <T.Input
          ref={usernameInputRef}
          placeholder={t("username")}
        />
        <T.Input
          ref={passwordInputRef}
          type="password"
          placeholder={t("password")}
        />
        <T.Input
          ref={rateInputRef}
          placeholder={t("rate")}
        />
      </div>
      <T.Gap
        height={24}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          gap: "16px",
        }}
      >
        <T.Button
          onClick={onCreateClick}
        >
          {t("create")}
        </T.Button>
        <T.Button
          onClick={onCancelClick}
        >
          {t("cancel")}
        </T.Button>
      </div>
    </T.Modal>
  </>);

  const renderNotification: JSX.Element = (<>
    <T.Notification
      ref={notificationRef}
    />
  </>);

  return (<>
    {render}
    {renderNotification}
    {renderModal}
  </>);
};


export default UserAgent;

import React from "react";

interface TGapProps extends React.HTMLAttributes<HTMLDivElement> {
  width?: React.CSSProperties["width"];
  height?: React.CSSProperties["height"];
}

const TGap = React.forwardRef<HTMLDivElement, TGapProps>((props, ref) => {

  let {
    width,
    height,
    style = {},
    ...restProps
  } = props;

  style = {
    width: `${width}px`,
    height: `${height}px`,
    ...style,
  } satisfies React.CSSProperties;

  return (
    <div
      ref={ref}
      style={style}
      {...restProps}
    />
  );
});

export default TGap;

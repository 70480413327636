import config from "~src/config";

const PERMANENT = 100;
const UNLIMITED_USER = 60000;

const Periods: PlanItemInterface[] = [
  {
    id: 1,
    title: "一年",
  },
  {
    id: 2,
    title: "两年",
  },
  {
    id: PERMANENT,
    title: "永久有效",
  },
];

const PeriodsUnchanged: PlanItemInterface = {
  id: 0,
  title: "不续订",
};

const Platforms: PlanItemInterface[] = [
  {
    id: 1,
    title: "单个平台",
    description: "任选 1 个平台客户端",
  },
  {
    id: 2,
    title: "两个平台",
    description: "任选 2 个平台客户端",
  },
  {
    id: 3,
    title: "全部平台",
    description: "全部 3 个平台客户端",
  },
  {
    id: 102,
    title: "iOS平台",
    description: "即将上线",
    disabled: true,
  },
];

const Users: PlanItemInterface[] = [
  {
    id: 100,
    title: "100人以下",
  },
  {
    id: 1000,
    title: "1000人以下",
  },
  {
    id: UNLIMITED_USER,
    title: "无限制",
  },
];

const UsersUnchanged: PlanItemInterface = {
  id: 0,
  title: "人数不变",
};

const getTitle = (items: PlanItemInterface[], id: PlanItemInterface["id"]) => {
  return items.find((item) => item.id === id)?.title;
};

const priceCalculator = (
  platform: PlanItemInterface["id"],
  user: PlanItemInterface["id"],
  period: PlanItemInterface["id"],
) => {
  // todo: calculate price

  const platformRate = ((_) => {
    switch (_) {
      case 1:
        return 1;
      case 2:
        return 2 * .95;
      case 3:
        return 3 * .9;
      default:
        return platform;
    }
  })(platform);
  let singlePrice = 99999;
  switch (user) {
    case 100: {
      switch (period) {
        case 1: {
          singlePrice = 69;
          break;
        }
        case 2: {
          singlePrice = 119;
          break;
        }
        case PERMANENT: {
          singlePrice = 149;
          break;
        }
      }
      break;
    }
    case 1000:{
      switch (period) {
        case 1: {
          singlePrice = 179;
          break;
        }
        case 2: {
          singlePrice = 299;
          break;
        }
        case PERMANENT: {
          singlePrice = 359;
          break;
        }
      }
      break;
    }
    case UNLIMITED_USER: {
      switch (period) {
        case 1: {
          singlePrice = 349;
          break;
        }
        case 2: {
          singlePrice = 599;
          break;
        }
        case PERMANENT: {
          singlePrice = 699;
          break;
        }
      }
      break;
    }
  }
  return Math.floor(singlePrice * platformRate * config.priceModifier);
};

const Plan = {
  Periods,
  PeriodsUnchanged,
  Platforms,
  Users,
  UsersUnchanged,
  getTitle,
  priceCalculator,

  PERMANENT,
  UNLIMITED_USER,
};

interface PlanItemInterface {
  id: number;
  title: string;
  description?: string;
  disabled?: boolean;
}

export default Plan;
export type {
  PlanItemInterface,
};

import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import bridge from "~src/backend/bridge";
import T from "~src/components";
import R from "~src/res";
import theme from "~src/theme";
import toolbox from "~src/toolbox";
import * as types from "~src/types";

const UserOrder: React.FC = () => {

  const notificationRef = useRef<types.TNotificationInstance>(null);
  const activeRef = useRef<{
    orderId: number;
    currency?: keyof typeof bridge.currencyOptions;
  }>();

  const [ orders, setOrders ] = useState<types.Order[]>();

  const [ paymentConfigModalOpen, setPaymentConfigModalOpen ] = useState<boolean>(false);

  const { t } = useTranslation();

  const loadOrders = async () => {
    const r = await bridge.listOrders();
    if (r?.status !== "OK") {
      notificationRef.current!.open("ERROR", "Unable to retrieve orders");
      return;
    }
    setOrders(r.data.sort((a, b) => b.id - a.id));
  };

  const createOrder = async (orderId: number, currency: keyof typeof bridge.currencyOptions): Promise<boolean> => {
    const r = await bridge.tpCreateOrder({
      orderId: orderId,
      currency: currency,
      redirectUrl: document.location.href,
    });
    if (r?.status !== "OK") {
      notificationRef.current!.open("ERROR", r?.data as string || "Unable to initiate payment");
      return false;
    }
    if (r.data == undefined) {
      // admin probably
      await loadOrders();
      return true;
    }
    window.open((r.data as types.TPCreateOrder).data);
    return true;
  };

  const onPayClick = (orderId: number) => {
    activeRef.current = {
      orderId: orderId,
    };
    setPaymentConfigModalOpen(true);
  };

  const onPaymentConfigModalClose = () => {
    setPaymentConfigModalOpen(false);
  };

  const onCurrencyChange = (value: keyof typeof bridge.currencyOptions) => {
    activeRef.current!.currency = value;
  };

  const onPaymentConfirm = async () => {
    if (activeRef.current?.currency === undefined) {
      notificationRef.current!.open("ERROR", "Please choose one currency");
      return;
    }
    const isCreated = await createOrder(activeRef.current!.orderId, activeRef.current.currency);
    if (!isCreated) {
      return;
    }
    notificationRef.current!.open("SUCCESS", "Please check the new window/tab to proceed the payment");
    onPaymentConfigModalClose();
  };

  useEffect(() => {
    return toolbox.useAsyncEffectOnce(async () => {
      await loadOrders();
    });
  }, []);

  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        loadOrders().then();
      }
    };
    window.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, []);

  const render: JSX.Element = (<>
    <T.Table
      dataSource={orders}
      rowKey={(_) => _.id}
    >
      <T.Table.Column
        title="ID"
        dataIndex="id"
        key="id"
      />
      <T.Table.Column
        title={t("status")}
        dataIndex="status"
        key="id"
        render={(_: string) => (
          <span
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {t(`order_${_.toLowerCase()}`)}
          </span>
        )}
      />
      <T.Table.Column
        title={t("name")}
        dataIndex={["data", "name"]}
        key="id"
      />
      <T.Table.Column
        title={t("platform")}
        dataIndex={["data", "platform"]}
        key="id"
        render={(_: string[]) => _.join(" / ")}
      />
      <T.Table.Column
        title={t("price")}
        key="id"
        render={(_, record: types.Order) => `$${Math.round(record.amount * (1 - record.discount) * 100) / 100}`}
      />
      <T.Table.Column
        title={t("created_at")}
        dataIndex="created_at"
        key="id"
        render={(_) => DateTime.fromISO(_).toLocaleString(DateTime.DATETIME_SHORT)}
      />
      <T.Table.Column
        title={t("paid_at")}
        dataIndex="paid_at"
        key="id"
        render={(_) => _ !== null ? DateTime.fromISO(_).toLocaleString(DateTime.DATETIME_SHORT) : "-"}
      />
      <T.Table.Column
        title={t("action")}
        key="id"
        render={(_, record: types.Order) => (record.status === bridge.OrderStatus.Pending &&
          <T.Button
            onClick={() => onPayClick(record.id)}
          >
            {t("pay")}
          </T.Button>
        )}
      />
    </T.Table>
  </>);

  const renderModal: JSX.Element = (<>
    <T.Modal
      isOpen={paymentConfigModalOpen}
      onRequestClose={onPaymentConfigModalClose}
    >
      <div
        style={{
          padding: "16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <T.Typography
            style={{
              alignSelf: "center",
              fontWeight: 600,
              fontSize: "16px",
              color: theme.palette.dark,
            }}
          >
            Payment Configuration
          </T.Typography>
          <T.Icon
            icon={R.CloseIcon}
            style={{
              padding: "6px",
            }}
            onClick={onPaymentConfigModalClose}
          />
        </div>
        <T.Gap
          height={16}
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            alignItems: "center",
            gap: "12px 16px",
          }}
        >
          <PaymentConfigModalItem
            title="Currency"
          >
            <T.Select
              style={{
                minWidth: "180px",
              }}
              onChange={onCurrencyChange}
            >
              {Object.entries(bridge.currencyOptions).map(([k, v]) => {
                const Icon = getIconByCurrency(k as keyof typeof bridge.currencyOptions);
                return (
                  <T.Select.Option
                    key={k}
                    value={k}
                    label={v}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Icon
                        width={14}
                        height={14}
                      />
                      <T.Typography
                        style={{
                          color: theme.palette.dark,
                        }}
                      >
                        {v}
                      </T.Typography>
                    </div>
                  </T.Select.Option>
                );
              })}
            </T.Select>
          </PaymentConfigModalItem>
        </div>
        <T.Gap
          height={16}
        />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
            gap: "16px",
          }}
        >
          <T.Button
            onClick={onPaymentConfirm}
          >
            Confirm
          </T.Button>
          <T.Button
            onClick={onPaymentConfigModalClose}
          >
            Cancel
          </T.Button>
        </div>
      </div>
    </T.Modal>
  </>);

  const renderNotification: JSX.Element = (<>
    <T.Notification
      ref={notificationRef}
    />
  </>);

  return (<>
    {render}
    {renderModal}
    {renderNotification}
  </>);
};

const PaymentConfigModalItem: React.FC<{
  title: string;
  children?: React.ReactNode;
}> = (props) => {

  const {
    title,
    children,
  } = props;

  return (<>
    <T.Typography
      style={{
        fontSize: "14px",
        color: theme.palette.neutral[7],
      }}
    >
      {title}
    </T.Typography>
    {children}
  </>);
};

const getIconByCurrency = (currency: keyof typeof bridge.currencyOptions) => {
  switch (currency) {
    default: {
      return R.TetherUsdtIcon;
    }
  }
};

export default UserOrder;

import { AccountBookOutlined, AppstoreAddOutlined, HddOutlined, MenuOutlined } from "@ant-design/icons";
import { ReactComponent as AccountIcon } from "~res/account.svg";
import AppBanner from "~res/app_banner.png";
import { ReactComponent as AppIcon } from "~res/app_icon1.svg";
import { ReactComponent as AppIcon2 } from "~res/app_icon2.svg";
import AppIcon2Image from "~res/app icon 2.png";
import AppIcon2BackgroundImage from "~res/app_icon2_background.png";
import PurchaseBackgroundImage from "~res/bg.png";
import HomeBannerVideo from "~res/bg test.mp4";
import { ReactComponent as CloseIcon } from "~res/Frame103.svg";
import { ReactComponent as ArrowIcon } from "~res/icon-arrow.svg";
import { ReactComponent as BuyIcon } from "~res/icon_buy.svg";
import { ReactComponent as CustomerServiceIcon2 } from "~res/icon-cs.svg";
import { ReactComponent as CustomerServiceIcon } from "~res/icon_cs.svg";
import { ReactComponent as DashboardIcon } from "~res/icon_dashboard.svg";
import { ReactComponent as EasyIcon } from "~res/icon_easy.svg";
import { ReactComponent as GroupIcon } from "~res/icon_group.svg";
import { ReactComponent as MailIcon } from "~res/icon-mail.svg";
import { ReactComponent as OssIcon } from "~res/icon_oss.svg";
import { ReactComponent as PanelsIcon } from "~res/icon_panels.svg";
import { ReactComponent as PlatformsIcon } from "~res/icon-platforms.svg";
import { ReactComponent as ProtocolIcon } from "~res/icon_protocol.svg";
import { ReactComponent as ServiceIcon } from "~res/icon_service.svg";
import { ReactComponent as TechIcon } from "~res/icon_tech.svg";
import { ReactComponent as TelegramIcon } from "~res/icon-tg.svg";
import { ReactComponent as TimeIcon } from "~res/icon-time.svg";
import { ReactComponent as TunIcon } from "~res/icon_TUN.svg";
import { ReactComponent as UsersIcon } from "~res/icon-users.svg";
import HomeBanner from "~res/image 1.png";
import TrayIcon1 from "~res/mac_tray_icon_for_light_mode_01@2x.png";
import TrayIcon2 from "~res/mac_tray_icon_for_light_mode_02@2x.png";
import TrayIcon3 from "~res/mac_tray_icon_for_light_mode_03@2x.png";
import TrayIcon4 from "~res/mac_tray_icon_for_light_mode_04@2x.png";
import TrayIcon5 from "~res/mac_tray_icon_for_light_mode_05@2x.png";
import TrayIcon6 from "~res/mac_tray_icon_for_light_mode_06@2x.png";
import TrayIcon7 from "~res/mac_tray_icon_for_light_mode_07@2x.png";
import TrayIcon8 from "~res/mac_tray_icon_for_light_mode_08@2x.png";
import TrayIcon9 from "~res/mac_tray_icon_for_light_mode_09@2x.png";
import { ReactComponent as AndroidIcon } from "~res/pic_Android_logo.svg";
import PurchaseBanner from "~res/pic-banner-buy.png";
import Cube1Image from "~res/pic_cube1.png";
import Cube2Image from "~res/pic_cube2.png";
import CustomerServiceSystemIconRaw from "~res/pic_customer_service_system.svg";
import CustomisationImage from "~res/pic_customize.png";
import { ReactComponent as IOSIcon } from "~res/pic_iOS_logo.svg";
import { ReactComponent as Logo } from "~res/pic_logo.svg";
import { ReactComponent as LogoBackground } from "~res/pic_logo_bg.svg";
import { ReactComponent as MacOSIcon } from "~res/pic_macOS_logo.svg";
import PlatformImage from "~res/pic_platforms.png";
import PurchaseSystemIconRaw from "~res/pic_purchase_system.svg";
import SupportBannerBackground from "~res/pic_banner_support_background.png";
import { ReactComponent as SupportBannerLogo } from "~res/pic_banner_support_logo.svg";
import ThemesImage from "~res/pic_themes.png";
import UiShowImage from "~res/pic_ui_show.png";
import { ReactComponent as WindowsIcon } from "~res/pic_Windows_logo.svg";
import { ReactComponent as PlaceholderIcon } from "~res/question_mark_black_24dp.svg";
import { ReactComponent as TetherUsdtIcon } from "~res/tether-usdt-logo.svg";

const R = {
  AccountIcon,
  AppBanner,
  AppIcon,
  AppIcon2,
  AppIcon2BackgroundImage,
  AppIcon2Image,
  AndroidIcon,
  ArrowIcon,
  BuyIcon,
  CloseIcon,
  Cube1Image,
  Cube2Image,
  CustomerServiceIcon,
  CustomerServiceIcon2,
  CustomerServiceSystemIconRaw,
  CustomisationImage,
  DashboardIcon,
  EasyIcon,
  GroupIcon,
  HomeBanner,
  HomeBannerVideo,
  IOSIcon,
  Logo,
  LogoBackground,
  MacOSIcon,
  MailIcon,
  OssIcon,
  PanelsIcon,
  PlaceholderIcon,
  PlatformImage,
  PlatformsIcon,
  ProtocolIcon,
  PurchaseBackgroundImage,
  PurchaseBanner,
  PurchaseSystemIconRaw,
  ServiceIcon,
  SupportBannerBackground,
  SupportBannerLogo,
  TechIcon,
  TelegramIcon,
  TetherUsdtIcon,
  ThemesImage,
  TimeIcon,
  TrayIcon1,
  TrayIcon2,
  TrayIcon3,
  TrayIcon4,
  TrayIcon5,
  TrayIcon6,
  TrayIcon7,
  TrayIcon8,
  TrayIcon9,
  TunIcon,
  UiShowImage,
  UsersIcon,
  WindowsIcon,
  // antd
  AccountBookOutlined,
  AppstoreAddOutlined,
  HddOutlined,
  MenuOutlined,
};

export default R;

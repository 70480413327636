import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Login from "~src/routes/Login";
import Purchase from "~src/routes/Purchase";
import Register from "~src/routes/Register";
import Support from "~src/routes/Support";
import Home from "~src/routes/Home";
import User from "~src/routes/User";
import UserAgent from "~src/routes/UserAgent";
import UserApp from "~src/routes/UserApp";
import UserCoupon from "~src/routes/UserCoupon";
import UserOrder from "~src/routes/UserOrder";
import UserProduct from "~src/routes/UserProduct";
import UserTask from "~src/routes/UserTask";

const userChildren = [
  // {
  //   path: "product",
  //   element: <UserProduct />,
  // },
  {
    path: "order",
    element: <UserOrder />,
  },
  {
    path: "app",
    element: <UserApp />,
  },
  {
    path: "task",
    element: <UserTask />,
  },
  {
    path: "agent",
    element: <UserAgent />,
  },
  {
    path: "coupon",
    element: <UserCoupon />,
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "purchase",
    element: <Purchase />,
  },
  {
    path: "support/",
    element: <Support />,
  },
  {
    path: "user/",
    element: <User />,
    children: userChildren,
  },
  {
    path: "login/",
    element: <Login />,
  },
  {
    path: "register/",
    element: <Register />,
  },
]);

const routes = {
  Purchase,
  Support,
  Home,
  User,
  UserOrder,
  UserProduct,
  router,
  userChildren,
};

export default routes;

import { notification } from "antd";
import React, { useImperativeHandle, useRef, useState } from "react";
import Modal from "react-modal";
import TTypography from "~src/components/TTypography";
import theme from "~src/theme";

const defaultDuration = 1500;

interface TNotificationProps extends Omit<Modal.Props, "isOpen"> {
}

const TNotification = React.forwardRef<TNotificationInstance, TNotificationProps>((props, ref) => {

  const {
    style: {
      overlay: overlayStyle,
      content: contentStyle,
    } = {},
  } = props;

  const timeoutRef = useRef<number>();

  const [ content, setContent ] = useState<JSX.Element>();
  const [ open, setOpen ] = useState<boolean>(false);

  const play = (duration: number = defaultDuration) => {
    if (timeoutRef.current !== undefined) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
    setOpen(true);
    if (duration) {
      setTimeout(() => {
        setOpen(false);
        timeoutRef.current = undefined;
      }, duration);
    }
  };

  useImperativeHandle(ref, () => ({
    open: (title, description, duration) => {
      setContent(renderContent(title, description));
      play(duration);
    },
  }));

  return (
    <Modal
      style={{
        overlay: {
          minWidth: "20vw",
          inset: "auto auto 32px 32px",
          background: "transparent",
          ...overlayStyle,
        },
        content: {
          position: "static",
          inset: 0,
          padding: 0,
          borderRadius: "8px",
          ...contentStyle,
        },
      }}
      appElement={document.body}
      isOpen={open}
   >
      {content}
    </Modal>
  );
});

const renderContent = (title: string, description: string): JSX.Element => (
  <div
    style={{
      padding: "16px",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    }}
  >
    <TTypography
      style={{
        fontSize: "20px",
        color: theme.palette.dark,
      }}
    >
      {title}
    </TTypography>
    <TTypography
      style={{
        color: theme.palette.dark,
      }}
    >
      {description}
    </TTypography>
  </div>
);

interface TNotificationInstance {
  open: (title: string, description: string, duration?: number) => void;
}

const TAntNotification = React.forwardRef<TNotificationInstance>((props, ref) => {

  const [ api, contextHolder ] = notification.useNotification();

  useImperativeHandle(ref, () => ({
    open: (title, description, duration = defaultDuration) => {
      api.open({
        message: title,
        description: description,
        duration: duration / 1000,
        placement: "bottomRight",
      });
    },
  }), []);

  return contextHolder;
});

// export default TNotification;
export default TAntNotification;
export type {
  TNotificationInstance,
};

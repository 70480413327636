import { Menu, MenuProps, MenuRef } from "antd";
import React from "react";

interface TAntMenuProps extends MenuProps {
}

const TAntMenu = React.forwardRef<MenuRef, TAntMenuProps>((props, ref) => {
  return (
    <Menu
      ref={ref}
      {...props}
    />
  );
});

export default TAntMenu;

import React from "react";

const UserProduct: React.FC = () => {
  return (
    <div
      style={{
        width: "100px",
        height: "100px",
        background: "black",
      }}
    />
  );
};

export default UserProduct;

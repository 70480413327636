import axios, { AxiosRequestConfig } from "axios";
import config from "~src/config";
import R from "~src/res";
import toolbox from "~src/toolbox";

const buildUrl = (path: string) => `${config.backendUrl}${path}`;

const commonAxiosConfig: AxiosRequestConfig = {
  withCredentials: true,
  validateStatus: () => true,
};

const register = async ({username, password, downline = false, rate}: RegistrationParams) => {
  const r = await axios.post<Response<string>>(
    buildUrl("/register"), {
      username,
      password,
      downline,
      rate,
    },
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

const login = async ({username, password}: LoginParams) => {
  const r = await axios.post<Response<string>>(
    buildUrl("/login"), {
      username,
      password,
    },
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

const logout = async () => {
  const r = await axios.post(
    buildUrl("/logout"),
    undefined,
    commonAxiosConfig,
  );
  return r;
};

const changePassword = async ({oldPassword, newPassword}: ChangePasswordParams) => {
  const r = await axios.post<Response<string>>(
    buildUrl("/change_password"), {
      oldPassword,
      newPassword,
    },
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

const isOnline = async () => {
  const r = await axios.post<Response<User>>(
    buildUrl("/is_online"),
    undefined,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

// ********************************

const listAgents = async () => {
  const r = await axios.post<Response<Agent[] | string>>(
    buildUrl("/list_agents"),
    undefined,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

const getIncrementalOptions = async (params: IncrementalOptionParams) => {
  const r = await axios.post<Response<IncrementalOption | string>>(
    buildUrl("/get_incremental_options"),
    params,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

const createUpgradeOrder = async (params: UpgradeParams) => {
  const r = await axios.post<Response<CreateOrder | string>>(
    buildUrl("/create_upgrade_order"),
    params,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

const createRenewalOrder = async (params: RenewalParams) => {
  const r = await axios.post<Response<CreateOrder | string>>(
    buildUrl("/create_renewal_order"),
    params,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

const createOrder = async (params: CreateOrderParams) => {
  const r = await axios.post<Response<CreateOrder | string>>(
    buildUrl("/create_order"),
    params,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

const generateCoupon = async (params: GenerateCouponParams) => {
  const r = await axios.post<Response<GenerateCoupon | string>>(
    buildUrl("/generate_coupon"),
    params,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

const checkCoupon = async (params: CheckCouponParams) => {
  const r = await axios.post<Response<CheckCoupon | string>>(
    buildUrl("/check_coupon"),
    params,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

const listCoupons = async () => {
  const r = await axios.post<Response<Coupon[]>>(
    buildUrl("/list_coupons"),
    undefined,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};


// ********************************

const listLicenses = async () => {
  const r = await axios.post<Response<License[]>>(
    buildUrl("/list_licenses"),
    undefined,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

const listTasks = async () => {
  const r = await axios.post<Response<Task[]>>(
    buildUrl("/list_tasks"),
    undefined,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

const listOrders = async () => {
  const r = await axios.post<Response<Order[]>>(
    buildUrl("/list_orders"),
    undefined,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

const querySeats = async (data: QuerySeatsParams) => {
  const r = await axios.post<Response<Seats>>(
    buildUrl("/query_seats"),
    data,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

const listPlans = async () => {
  const r = await axios.post<Response<Plan>>(
    buildUrl("/list_plans"),
    undefined,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

// ********************************

const submitIcon = async (f: File) => {
  const md5 = await toolbox.getMd5(f);
  const r = await axios.post<Response<Icon>>(
    buildUrl("/submit_icon"),
    f, {
      ...commonAxiosConfig,
      params: {
        ["file_name"]: f.name,
        ["file_hash"]: md5,
      },
      headers: {
        "Content-Type": f.type,
      },
    },
  );
  return r.data as typeof r.data | undefined;
};

const getPreviewIconUrl = (iconHash: string): string =>  {
  return buildUrl(`/preview_icon?icon_hash=${iconHash}`);
};

const configureLicense = async (data: LicenseParams) => {
  const r = await axios.post<Response<never>>(
    buildUrl("/configure_license"),
    data,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

const submitTask = async (data: TaskParams) => {
  const r = await axios.post<Response<Build>>(
    buildUrl("/submit_task"),
    data,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

const encryptProxy = async (data: EncryptProxyParams) => {
  const r = await axios.post<Response<EncryptedProxy>>(
    buildUrl("/encrypt_proxy"),
    data,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

// ********************************

interface RegistrationParams {
  username: string;
  password: string;
  downline?: boolean;
  rate?: number;
}

interface LoginParams {
  username: string;
  password: string;
}

interface ChangePasswordParams {
  oldPassword: string;
  newPassword: string;
}

interface IncrementalParams {
  licenseId: number;
  seats?: number;
  renewal?: number;
}

interface IncrementalOptionParams {
  licenseId: number;
}

interface UpgradeParams {
  licenseId: number;
  user: number;
}

interface RenewalParams {
  licenseId: number;
  period: number;
}

interface CreateOrderParams {
  platform: number;
  user: number;
  period: number;
  name: string;
  platformNames: string[];
  coupon: string;
}

interface GenerateCouponParams {
  discount: number;
  count: number;
  expiry?: string;
}

interface CheckCouponParams {
  code: string;
}

interface QuerySeatsParams {
  licenseId: number;
}

interface LicenseParams {
  licenseId: number;
  serverType?: keyof typeof ServerType;
  subFlag?: string;
  url?: string;
  oss?: string;
  proxy?: string;
  homepage?: string;
  support_api?: string;
  telegram?: string;
  tos?: string;
  packageName?: string;
  colour?: string;
  iconId?: number;
  iconHash?: string;
  icon2Id?: number;
  icon2Hash?: string;
  bannerId?: number;
  bannerHash?: string;
  trayIconId?: number;
  // todo: platform-dependent
}

interface TaskParams {
  licenseId: number;
  testBuild: boolean;
}

interface EncryptProxyParams {
  proxy: string;
}

interface Seats extends Model {
  used?: number;
  total?: number;
}

interface License extends Model {
  id: number;
  user: number;
  name: string;
  server_type: keyof typeof ServerType;
  sub_flag: string;
  url: string;
  oss: string;
  proxy: string;
  colour: string;
  icon: number;
  icon_hash: string;
  icon2: number | undefined;
  icon2_hash: string;
  banner: number | undefined;
  banner_hash: string;
  tray_icon: number;
  homepage: string;
  telegram: string;
  tos: string;
  support_api: string;
  // platform-dependent
  package_name: string;
  signing_key: string;
  // restriction
  platform: keyof typeof Platform;
  max_users: number;
  expiry: string;
  domain: string;
  // management
  status: LicenseStatus;
  created_at: string;
  modified_at: string;
}

interface Icon extends Model {
  icon_id: number;
}

interface Build extends Model {
  task_id: number;
}

interface EncryptedProxy extends Model {
  encrypted_proxy: string;
}

interface Task extends Model {
  id: number;
  user: number;
  license: number;
  data: TaskData;
  download_path: string;
  // management
  status: TaskStatus;
  created_at: string;
  finished_at: string;
}

interface TaskData {
  name: string;
  url: string;
  colour: string;
  icon: number;
}

interface Order extends Model {
  id: number;
  user: number;
  license: number;
  data: {
    name: string;
    platform: string;
    user_cap: number;
    expiry: string;
  };
  amount: number;
  payment_method: number;
  discount: number;
  status: OrderStatus;
  created_at: string;
  paid_at: string;
}

interface Plan extends Model {
  periods: {
    id: number;
    title: string;
  }[];
  platforms: {
    id: number;
    title: string;
    description: string;
  };
  users: {
    id: number;
    title: string;
  }[];
}

interface IncrementalOption extends Model {
  upgrade: Record<number, number>;
  renewal: Record<number, number>;
}

interface CreateOrder extends Model {
  order_id: number;
}

interface GenerateCoupon extends Model {
  coupon_id: number;
}

interface CheckCoupon extends Model {
  discount: number;
}

interface Coupon extends Model {
  id: number;
  code: string;
  discount: number;
  count: number;
  expiry: string;
  status: string;
  created_at: string;
  used_at: string;
}

interface User extends Model {
  username: string;
  role: keyof typeof UserRole;
}

interface Agent extends Model {
  username: string;
  rate: number;
}

enum ServerType {
  V2Board = "V2Board",
  SSPanelUim = "SSPanelUim",
  SSPanelMetron = "SSPanelMetron",
  SSPanelMalio = "SSPanelMalio",
  SSPanelCool = "SSPanelCool",
  WHMCS = "WHMCS",
}

enum Platform {
  MacOS = "MacOS",
  Windows = "Windows",
  Android = "Android",
}

enum LicenseStatus {
  Normal,
  Disabled,
}

enum OrderStatus {
  Pending = "Pending",
  Paid = "Paid",
}

enum TaskStatus {
  Pending = "Pending",
  Started = "Started",
  Failed = "Failed",
  Finished = "Finished",
}

enum UserRole {
  Client = "Client",
  Admin = "Admin",
  PrimaryAgent = "Primary Agent",
  SecondaryAgent = "Secondary Agent",
}

// append only: index is used as tray icon id
const TrayIcons = [
  R.TrayIcon2,
  R.TrayIcon1,
  R.TrayIcon3,
  R.TrayIcon4,
  R.TrayIcon5,
  R.TrayIcon6,
  R.TrayIcon7,
  R.TrayIcon8,
  R.TrayIcon9,
];

// ****************  TokenPay  ****************

const tpCreateOrder = async (data: PaymentParams) => {
  const r = await axios.post<Response<TPCreateOrder | string>>(
    buildUrl("/tp_create_order"),
    data,
    commonAxiosConfig,
  );
  return r.data as typeof r.data | undefined;
};

interface PaymentParams {
  orderId: number;
  currency: keyof typeof currencyOptions;
  redirectUrl: string;
}

interface TPCreateOrder extends Model {
  success: boolean;
  message: string;
  data: string;
  info: {
    ActualAmount: number;
    Amount: number;
    BaseCurrency: string;
    BlockChainName: string;
    CurrencyName: string;
    ExpireTime: string;
    Id: string;
    OrderUserKey: string;
    OutOrderId: string;
    QrCodeBase64: string;
    QrCodeLink: string;
    ToAddress: string;
  };
}

const currencyOptions = {
  USDT_TRC20: "USDT (TRC20)",
  // EVM_Polygon_USDT_ERC20: "USDT (Polygon)",
  EVM_ETH_USDT_ERC20: "USDT (ERC20)",
  EVM_Polygon_USDT_ERC20: "USDT (Polygon)",
  EVM_BSC_USDT_BEP20: "USDT (币安智能链)",
};

// ********************************

interface Response<T extends Model | Model[] | string> {
  status: "OK" | "ERROR";
  data: T;
}

interface Model {
}

const bridge = {
  register,
  login,
  logout,
  changePassword,
  isOnline,
  listAgents,
  getIncrementalOptions,
  createUpgradeOrder,
  createRenewalOrder,
  createOrder,
  generateCoupon,
  checkCoupon,
  listCoupons,
  listLicenses,
  listTasks,
  listOrders,
  querySeats,
  listPlans,
  submitIcon,
  getPreviewIconUrl,
  configureLicense,
  submitTask,
  encryptProxy,
  tpCreateOrder,
  currencyOptions,
  // enum
  OrderStatus,
  Platform,
  ServerType,
  UserRole,
  // data
  TrayIcons,
};

export default bridge;
export type {
  Agent,
  Coupon,
  IncrementalOption,
  GenerateCoupon,
  CheckCoupon,
  Seats,
  License,
  LicenseParams,
  Order,
  Task,
  TPCreateOrder,
};

import React from "react";
import T from "~src/components/index";
import theme from "~src/theme";

interface TDividerProps extends React.HTMLAttributes<HTMLDivElement> {
  vertical?: boolean;
  size?: number;
  colour?: React.CSSProperties['background'];
}

const TDivider: React.FC<TDividerProps> = (props) => {

  const {
    vertical = false,
    size = 1,
    colour = theme.palette.blackMask(.12),
    style,
    ...restProps
  } = props;

  return (
    <T.Gap
      style={{
        alignSelf: "stretch",
        background: colour,
        ...style,
      }}
      {...(vertical ? {
        width: size,
      } : {
        height: size,
      })}
      {...restProps}
    />
  );
};

export default TDivider;

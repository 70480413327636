import React from "react";
import { Tab, TabProps } from "react-tabs";

interface TTabProps extends TabProps {
}

const TTab = React.forwardRef<HTMLLIElement, TTabProps>((props, ref) => {
  return (
    <Tab
      ref={ref}
      {...props}
    />
  );
});

export default TTab;

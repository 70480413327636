import BottomBar from "~src/components/BottomBar";
import ChangePassword from "~src/components/ChangePassword";
import TTab from "~src/components/Tabs/TTab";
import TTabList from "~src/components/Tabs/TTabList";
import TTabPanel from "~src/components/Tabs/TTabPanel";
import TTabs from "~src/components/Tabs/TTabs";
import TButton from "~src/components/TButton";
import TColourPicker from "~src/components/TColourPicker";
import TDiv from "~src/components/TDiv";
import TDivider from "~src/components/TDivider";
import TDropdown from "~src/components/TDropdown";
import TGap from "~src/components/TGap";
import TIcon from "~src/components/TIcon";
import TImg from "~src/components/TImg";
import TInput from "~src/components/TInput";
import TMenu from "~src/components/TMenu";
import TModal from "~src/components/TModal";
import TMultiSelect from "~src/components/TMultiSelect";
import TNotification from "~src/components/TNotification";
import TPopover from "~src/components/TPopover";
import TopBar from "~src/components/TopBar";
import TPlayer from "~src/components/TPlayer";
import TSelect from "~src/components/TSelect";
import TTable from "~src/components/TTable";
import TTextArea from "~src/components/TTextArea";
import TTypography from "~src/components/TTypography";
import TUpload from "~src/components/TUpload";

const T = {
  BottomBar: BottomBar,
  ChangePassword: ChangePassword,
  Button: TButton,
  ColourPicker: TColourPicker,
  Div: TDiv,
  Divider: TDivider,
  Dropdown: TDropdown,
  Gap: TGap,
  Icon: TIcon,
  Img: TImg,
  Input: TInput,
  Menu: TMenu,
  Modal: TModal,
  MultiSelect: TMultiSelect,
  Notification: TNotification,
  Player: TPlayer,
  Popover: TPopover,
  Select: TSelect,
  Tab: TTab,
  Table: TTable,
  TabList: TTabList,
  TabPanel: TTabPanel,
  Tabs: TTabs,
  TextArea: TTextArea,
  TopBar: TopBar,
  Typography: TTypography,
  Upload: TUpload,
};

export default T;

import { InputProps } from "antd";
import { DateTime, Duration } from "luxon";
import { filetypeextension } from "magic-bytes.js";
import React, { CSSProperties, HTMLAttributes, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import yaml from "yaml";
import bridge from "~src/backend/bridge";
import Plan from "~src/backend/Plan";
import T from "~src/components";
import R from "~src/res";
import UserApp2 from "~src/routes/UserApp2";
import theme from "~src/theme";
import toolbox from "~src/toolbox";
import type * as types from "~src/types";

const UserApp: React.FC = () => {

  const navigate = toolbox.useNavigate();

  const formRef = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<types.TNotificationInstance>(null);
  const upgradeModalRef = useRef<types.UpgradeModalInstance>(null);
  const renewalModalRef = useRef<types.RenewalModalInstance>(null);

  const serverTypeRef = useRef<keyof typeof bridge.ServerType>();

  const [ activeLicense, setActiveLicense ] = useState<types.License>();
  const [ licenses, setLicenses ] = useState<types.License[]>();

  const [ colours, setColours ] = useState<string[]>(theme.palette.preset);
  const [ currentColour, setCurrentColour ] = useState<string>();

  const [ currentTrayIcon, setCurrentTrayIcon ] = useState<number>(0);

  // application modal
  const appSubFlagRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const appUrlRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const appOssRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const appProxyRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const appHomepageRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const appSupportApiRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const appTelegramRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const appTosRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const appPackageNameRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const appColourRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const appIconRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const appIcon2Ref = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const appBannerRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const previewIconRef = useRef<HTMLImageElement>(null);
  const previewBannerRef = useRef<HTMLImageElement>(null);
  const previewIcon2Ref = useRef<HTMLImageElement>(null);
  const previewBannerContainerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const updateLicenses = async () => {
    const r = await bridge.listLicenses();
    if (r?.status !== "OK") {
      notificationRef.current!.open("ERROR", "Unable to load applications");
      return;
    }
    setLicenses(r.data);
  };

  const onItemClick = async (application: types.License) => {
    if (application !== activeLicense) {
      setActiveLicense(application);
    } else {
      setActiveLicense(undefined);
    }
  };

  const onIconChange = async () => {
    await _onIconChange(appIconRef, previewIconRef);
  };

  const onIcon2Change = async () => {
    await _onIconChange(appIcon2Ref, previewIcon2Ref);
    if (previewIcon2Ref.current!.style.display === "none") {
      activeLicense!.icon2 = undefined;
    }
  };

  const onBannerChange = async () => {
    await _onIconChange(appBannerRef, previewBannerRef, previewBannerContainerRef);
    if (previewBannerContainerRef.current!.style.display === "none") {
      activeLicense!.banner = undefined;
    }
  };

  const _onIconChange = async (icon: React.RefObject<HTMLInputElement>, preview: React.RefObject<HTMLImageElement>, container?: React.RefObject<HTMLElement>) => {
    const appIcon = icon.current!.files!.item(0);
    if (appIcon === null) {
      preview.current!.style.display = "none";
      if (container) {
        container.current!.style.display = "none";
      }
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = (ev) => {
      const iconDataUrl = ev.target!.result;
      preview.current!.src = iconDataUrl as string;
      preview.current!.style.display = "block";
      if (container) {
        container.current!.style.display = "block";
      }
    };
    fileReader.readAsDataURL(appIcon);
  };

  const onSaveClick = async () => {
    const appServerType = serverTypeRef.current;
    const appSubFlag = appSubFlagRef.current!.value;
    const appUrl = appUrlRef.current!.value;
    const appOss = appOssRef.current!.value;
    const appProxy = getProxy(appProxyRef.current!.value);
    const appHomepage = appHomepageRef.current!.value;
    const appSupportApi = appSupportApiRef.current!.value;
    const appTelegram = appTelegramRef.current!.value;
    const appTos = appTosRef.current!.value;
    const appPackageName = appPackageNameRef.current!.value;
    const appColour = appColourRef.current!.value;
    const appIcon = appIconRef.current!.files!.item(0);
    const appIcon2 = appIcon2Ref.current!.files!.item(0);
    const appBanner = appBannerRef.current!.files!.item(0);

    const missingFields: string[] = [];
    if (!serverTypeRef.current && !activeLicense?.server_type) {
      missingFields.push("server type");
    }
    if (!appUrl && !activeLicense?.url) {
      missingFields.push("URL");
    }
    if (!appColour && !activeLicense?.colour) {
      missingFields.push("colour");
    }
    if (!appIcon && !activeLicense?.icon) {
      missingFields.push("icon");
    }
    if (missingFields.length) {
      notificationRef.current!.open("ERROR", `Mandatory fields ${missingFields.join(" / ")} missing`);
      return false;
    }

    if (appOss && !appOss.endsWith(".json")) {
      notificationRef.current!.open("ERROR", `OSS hosting URL does not end with ".json"`);
      return false;
    }
    if (appPackageName && /^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z][A-Za-z\d_]*$/.exec(appPackageName) === null) {
      notificationRef.current!.open("ERROR", `Invalid package name`);
      return false;
    }

    const data: types.LicenseParams = {
      licenseId: activeLicense!.id,
    };
    if (appServerType) data.serverType = appServerType;
    if (appSubFlag) data.subFlag = appSubFlag;
    if (appUrl) data.url = appUrl;
    if (appOss) data.oss = appOss;
    if (appProxy) data.proxy = appProxy;
    if (appHomepage) data.homepage = appHomepage;
    if (appSupportApi) data.support_api = appSupportApi;
    if (appTelegram) data.telegram = appTelegram;
    if (appTos) data.tos = appTos;
    if (appPackageName) data.packageName = appPackageName;
    if (appColour) data.colour = appColour;
    if (appIcon) {
      const iconId = await _submitIcon(appIcon);
      if (iconId == null) {
        return false;
      }
      data.iconId = iconId;
      data.iconHash = await toolbox.getMd5(appIcon);
    }
    if (appIcon2) {
      const icon2Id = await _submitIcon(appIcon2);
      if (icon2Id == null) {
        return false;
      }
      data.icon2Id = icon2Id;
      data.icon2Hash = await toolbox.getMd5(appIcon2);
    } else {
      data.icon2Id = activeLicense?.icon2;
    }
    if (appBanner) {
      const bannerId = await _submitIcon(appBanner);
      if (bannerId == null) {
        return false;
      }
      data.bannerId = bannerId;
      data.bannerHash = await toolbox.getMd5(appBanner);
    } else {
      data.bannerId = activeLicense?.banner;
    }
    data.trayIconId = currentTrayIcon;
    const r1 = await bridge.configureLicense(data);
    if (r1?.status !== "OK") {
      notificationRef.current!.open("ERROR", r1?.data || "Unable to update configuration");
      return false;
    }

    notificationRef.current!.open("SUCCESS", "Configuration updated");
    await updateLicenses();
    await onItemClick(activeLicense!);
    return true;
  };

  const _submitIcon = async (appIcon: File): Promise<number | null> => {
    if (appIcon.size > 10 * 1024 ** 2) {
      notificationRef.current!.open("ERROR", `Icon size too large (${appIcon.size / 1024 ** 2}MB > 10MB)`);
      return null;
    }
    const appIconSampleBuffer = await appIcon.slice(0, Math.min(0x1000, appIcon.size)).arrayBuffer();
    const appIconSampleArray = Array.from(new Uint8Array(appIconSampleBuffer));
    const appIconExt = filetypeextension(appIconSampleArray)[0];
    switch (appIconExt) {
      case "png": {
        break;
      }
      default: {
        notificationRef.current!.open("ERROR", `Currently, only PNG icon is supported`);
        return null;
      }
    }
    const r0 = await bridge.submitIcon(appIcon!);
    if (r0?.status !== "OK") {
      notificationRef.current!.open("ERROR", "Unable to upload icon");
      return null;
    }
    return r0.data.icon_id;
  };

  const _onBuildClick = async (testBuild: boolean) => {
    const isSaved = await onSaveClick();
    if (!isSaved) {
      return;
    }
    const r = await bridge.submitTask({
      licenseId: activeLicense!.id,
      testBuild: testBuild,
    });
    if (r?.status !== "OK") {
      notificationRef.current!.open("ERROR", "Unable to create build task");
      return;
    }
    notificationRef.current!.open("SUCCESS", `Build task (ID: ${r.data.task_id}) has been created`);
  };

  const onBuildClick = async () => {
    await _onBuildClick(false);
  };

  const onTestingBuildClick = async () => {
    await _onBuildClick(true);
  };

  const onExportClick = async () => {
    const appServerType = serverTypeRef.current;
    const appUrl = appUrlRef.current!.value;
    const appProxy = getProxy(appProxyRef.current!.value);
    const appHomepage = appHomepageRef.current!.value;
    const appSupportApi = appSupportApiRef.current!.value;
    const appTelegram = appTelegramRef.current!.value;

    const missingFields: string[] = [];
    if (!appServerType && !activeLicense?.server_type) {
      missingFields.push("server type");
    }
    if (!appUrl && !activeLicense?.url) {
      missingFields.push("URL");
    }
    if (missingFields.length) {
      notificationRef.current!.open("ERROR", `Mandatory fields (${missingFields.join(" / ")}) missing`);
      return false;
    }

    // fixme: should not expose internal id to users
    const getServerTypeId = (name: keyof typeof bridge.ServerType): number | undefined => {
      switch (name) {
        case "V2Board": return 0;
        case "SSPanelUim": return 1;
        case "SSPanelMetron": return 2;
        case "SSPanelMalio": return 3;
        case "SSPanelCool": return 4;
        case "WHMCS": return 5;
        default: return undefined;
      }
    };

    let encryptedProxy: string | undefined = undefined;
    const plainProxy = appProxy || activeLicense?.proxy;
    if (plainProxy) {
      const y = yaml.parse(plainProxy);
      if (!(y?.server && y?.type)) {
        notificationRef.current!.open("ERROR", "invalid built-in proxy");
        return false;
      }
      const r = await bridge.encryptProxy({ proxy: plainProxy });
      if (r?.status !== "OK") {
        notificationRef.current!.open("ERROR", "cannot obtain encrypted proxy info");
        return false;
      }
      encryptedProxy = r.data.encrypted_proxy;
    }

    const ossJson = JSON.stringify({
      RemoteHosts: (appUrl || activeLicense!.url).split("\n"),
      RemoteType: getServerTypeId(appServerType! || activeLicense!.server_type),
      HomePage: appHomepage || activeLicense?.homepage,
      SupportApi: appSupportApi || activeLicense?.support_api,
      TelegramGroup: appTelegram || activeLicense?.telegram,
      BuiltInProxy: encryptedProxy || "",
    });
    await navigator.clipboard.writeText(ossJson);
    notificationRef.current!.open("SUCCESS", "OSS JSON has been copied to the clipboard");
  };

  useEffect(() => {
    if (activeLicense !== undefined) {
      serverTypeRef.current = undefined;
      setCurrentColour(activeLicense.colour);
      setCurrentTrayIcon(activeLicense.tray_icon);
    }
  }, [
    activeLicense,
  ]);

  useEffect(() => {
    return toolbox.useAsyncEffectOnce(async () => {
      await updateLicenses();
    });
  }, []);

  const render: JSX.Element = (<>
    <div
      style={{
        width: "100%",
        paddingBottom: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill,minmax(200px, 1fr))",
          gap: "16px",
        }}
      >
        {licenses?.map((v, i) =>
          <Item
            key={i}
            license={v}
            active={v === activeLicense}
            onClick={onItemClick}
          />
        )}
      </div>
      {activeLicense !== undefined &&
        <div
          ref={formRef}
          key={activeLicense?.id}
          style={{
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <T.Divider />
          <div
            style={{
              padding: "0 8px",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                padding: "8px 0",
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <T.Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "20px",
                    color: theme.palette.dark,
                  }}
                >
                  {activeLicense?.name}
                </T.Typography>
                <T.Typography
                  style={{
                    color: theme.palette.dark,
                  }}
                >
                  {activeLicense?.platform}
                  {toolbox.commaPrefix(toolbox.formatExpiryDate(DateTime.fromISO(activeLicense?.expiry)))}
                  <SeatItem activeLicense={activeLicense} />
                </T.Typography>
              </div>
              <div
                style={{
                  flex: "1 1 0",
                }}
              />
              <UserApp2.IncrementalItem
                activeLicense={activeLicense}
                upgradeModalRef={upgradeModalRef}
                renewalModalRef={renewalModalRef}
              />
              <R.CloseIcon
                style={{
                  padding: "6px",
                  cursor: "pointer",
                }}
                onClick={() => setActiveLicense(undefined)}
              />
            </div>
            <T.Gap
              height={16}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Item2
                title={t("name")}
                placeholder={activeLicense?.name || ''}
                disabled
              />
              <Item2
                title={t("server_type")}
                placeholder={activeLicense?.server_type}
                inputState={[
                  undefined,
                  (_) => serverTypeRef.current = _ as keyof typeof bridge.ServerType,
                ]}
                select={true}
                options={(Object.keys(bridge.ServerType)).map(_ => ({
                  value: _,
                  label: _,
                }))}
              />
              <Item2
                ref={appSubFlagRef}
                title={`${t("sub_flag")}${t("optional")}`}
                placeholder={activeLicense?.sub_flag}
              />
              <Item2
                ref={appUrlRef}
                title={`${t("urls")}${t("one_per_line")}`}
                placeholder={activeLicense?.url}
                multiline={true}
              />
              <Item2
                ref={appOssRef}
                title={`${t("oss_hosting_url")}\n${t("one_per_line")}\n${t("optional")}`}
                placeholder={activeLicense?.oss || t("oss_hosting_url_placeholder")}
                defaultValue={activeLicense?.oss || ""}
                multiline={true}
                minRows={2}
                maxRows={4}
              />
              <Item2
                ref={appProxyRef}
                title={`${t("built_in_proxy")}${t("optional")}`}
                placeholder={activeLicense?.proxy}
              />
              <Item2
                ref={appHomepageRef}
                title={`${t("homepage_url")} ${t("optional")}`}
                placeholder={activeLicense?.homepage}
              />
              <Item2
                ref={appSupportApiRef}
                title={`${t("support_api")} ${t("optional")}`}
                placeholder={activeLicense?.support_api}
              />
              <Item2
                ref={appTelegramRef}
                title={`${t("telegram_group")}${t("optional")}`}
                placeholder={activeLicense?.telegram}
              />
              <Item2
                ref={appTosRef}
                title={`${t("terms_of_service")}${t("optional")}`}
                placeholder={activeLicense?.tos}
              />
              <div
                style={{
                  display: activeLicense?.platform === bridge.Platform.Android ? undefined : "none",
                }}
              >
                <Item2
                  ref={appPackageNameRef}
                  title={`${t("package_name")}${t("optional")}`}
                  placeholder={activeLicense?.package_name}
                />
              </div>
              <Item2
                ref={appColourRef}
                title={t("colour")}
                placeholder={activeLicense?.colour}
                inputState={[ currentColour, setCurrentColour ]}
                extra={
                  <T.ColourPicker
                    currentColourState={[ currentColour, setCurrentColour ]}
                    coloursState={[ colours, setColours ]}
                  />
                }
              />
              <Item2
                ref={appIconRef}
                title={t("icon")}
                inputType="file"
                extra={
                  <img
                    ref={previewIconRef}
                    style={{
                      // fixme: "display" will be dynamically updated, which is unmanaged by React
                      display: activeLicense?.icon_hash ? "inherit" : "none",
                      width: "64px",
                      height: "64px",
                    }}
                    src={activeLicense?.icon_hash ? bridge.getPreviewIconUrl(activeLicense?.icon_hash) : undefined}
                  />
                }
                onInputChange={onIconChange}
              />
              <div
                style={{
                  display: activeLicense?.platform === bridge.Platform.Android ? undefined : "none",
                }}
              >
                <Item2
                  ref={appIcon2Ref}
                  title={`${t("in_app_icon")}${t("optional")}`}
                  inputType="file"
                  extra={
                    <img
                      ref={previewIcon2Ref}
                      style={{
                        // fixme: "display" will be dynamically updated, which is unmanaged by React
                        display: activeLicense?.icon2_hash ? "inherit" : "none",
                        width: "64px",
                        height: "64px",
                      }}
                      src={activeLicense?.icon2_hash ? bridge.getPreviewIconUrl(activeLicense?.icon2_hash) : undefined}
                    />
                  }
                  onInputChange={onIcon2Change}
                />
              </div>
              <div
                style={{
                  display: activeLicense?.platform !== bridge.Platform.Android ? undefined : "none",
                }}
              >
                <Item2
                  ref={appBannerRef}
                  title={`${t("banner")}${t("parentheses", { content: "352*580" })}${t("optional")}`}
                  inputType="file"
                  extra={
                    <div
                      ref={previewBannerContainerRef}
                      style={{
                        // fixme: "display" will be dynamically updated, which is unmanaged by React
                        display: activeLicense?.banner_hash ? undefined : "none",
                        background: `no-repeat url(${R.AppBanner})`,
                        border: "1px dashed black",
                        boxSizing: "content-box",
                        width: "352px",
                        height: "580px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        ref={previewBannerRef}
                        style={{
                          // fixme: "display" will be dynamically updated, which is unmanaged by React
                          display: activeLicense?.banner_hash ? undefined : "none",
                        }}
                        src={activeLicense?.banner_hash ? bridge.getPreviewIconUrl(activeLicense?.banner_hash) : undefined}
                      />
                    </div>
                  }
                  onInputChange={onBannerChange}
                />
              </div>
              {activeLicense?.platform !== bridge.Platform.Android &&
                <Item2
                  title={`${t("tray_icon")}`}
                  hidden={true}
                  extra={
                    <TrayIconPicker
                      currentTrayIconState={[currentTrayIcon, setCurrentTrayIcon]}
                    />
                  }
                />
              }
            </div>
            <T.Gap
              height={32}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <T.Button
                style={{
                  padding: "0 16px",
                  minWidth: "128px",
                  height: "36px",
                }}
                onClick={onSaveClick}
              >
                {t("save")}
              </T.Button>
              <T.Button
                style={{
                  padding: "0 16px",
                  minWidth: "128px",
                  height: "36px",
                }}
                onClick={onBuildClick}
              >
                {t("save_and_build")}
              </T.Button>
              <T.Button
                style={{
                  padding: "0 16px",
                  minWidth: "128px",
                  height: "36px",
                }}
                onClick={onTestingBuildClick}
              >
                {`${t("save_and_build")}${t("parentheses", { content: t("testing_build") })}`}
              </T.Button>
              <T.Button
                style={{
                  padding: "0 16px",
                  minWidth: "128px",
                  height: "36px",
                }}
                onClick={onExportClick}
              >
                {t("export_oss_json")}
              </T.Button>
            </div>
          </div>
        </div>
      }
    </div>
  </>);

  const renderNotification: JSX.Element = (<>
    <T.Notification
      ref={notificationRef}
    />
  </>);

  const renderUpgradeModal: JSX.Element = (<>
    <UserApp2.UpgradeModalItem
      ref={upgradeModalRef}
    />
  </>);

  const renderRenewalModal: JSX.Element = (<>
    <UserApp2.RenewalModalItem
      ref={renewalModalRef}
    />
  </>);

  return (<>
    {render}
    {renderNotification}
    {renderRenewalModal}
    {renderUpgradeModal}
  </>);
};

const Item: React.FC<{
  license: types.License;
  active?: boolean;
  onClick: (application: types.License) => void;
}> = (props) => {

  const {
    license,
    active = false,
    onClick,
  } = props;

  return (
    <T.Div
      style={{
        height: "64px",
        padding: "8px",
        boxSizing: "border-box",
        cursor: "pointer",
        borderRadius: "8px",
        background: theme.palette.primaryMask(4, active ? .2 : .1),
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
      onClick={() => onClick(license)}
    >
      <T.Typography
        style={{
          fontWeight: "600",
          fontSize: "16px",
          color: theme.palette.dark,
        }}
      >
        {license.name}
      </T.Typography>
      <T.Typography
        style={{
          fontSize: "14px",
          color: theme.palette.dark,
        }}
      >
        {license.platform} {toolbox.bracket(toolbox.formatExpiryDuration(DateTime.fromISO(license.expiry).diffNow()))}
      </T.Typography>
    </T.Div>
  );
};

const SeatItem: React.FC<{
  activeLicense: types.License;
}> = (props) => {
  const {
    activeLicense,
  } = props;

  const notificationRef = useRef<types.TNotificationInstance>(null);

  const [ stats, setStats ] = useState<types.Seats>({});

  const rateUsed = (stats.used ?? 0) / (stats.total ?? 1);

  const unlimited = activeLicense.max_users === Plan.UNLIMITED_USER;

  useEffect(() => {
    (async () => {
      if (unlimited) {
        return;
      }
      const r = await bridge.querySeats({
        licenseId: activeLicense.id,
      });
      if (r?.status !== "OK") {
        notificationRef.current!.open("ERROR", "Unable to load current license usage");
        return;
      }
      setStats(r.data);
    })();
  }, [
    activeLicense.id,
  ]);

  if (unlimited) {
    return null;
  }

  const renderNotification: JSX.Element = (<>
    <T.Notification
      ref={notificationRef}
    />
  </>);

  const render: JSX.Element = (
    <span
      style={{
        ...((stats.total ?? stats.used) === undefined && {
          opacity: .5,
        } satisfies CSSProperties),
        ...(rateUsed > .8 && {
          color: theme.palette.gold[5],
        } satisfies CSSProperties),
        ...(rateUsed > 1 && {
          color: theme.palette.red[5],
        } satisfies CSSProperties),
      }}
    >
      , {stats.used ?? "-"}/{stats.total ?? "-"} seats used
    </span>
  );

  return (<>
    {render}
    {renderNotification}
  </>);
};

const Item2 = React.forwardRef<HTMLInputElement & HTMLTextAreaElement, {
  title: string;
  inputType?: React.HTMLInputTypeAttribute;
  inputState?: ReturnType<typeof useState<string>>;
  defaultValue?: HTMLAttributes<unknown>["defaultValue"];
  placeholder?: string;
  disabled?: boolean;
  hidden?: boolean;
  extra?: React.ReactNode;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  select?: boolean;
  options?: types.TSelectProps["options"];
  onInputChange?: () => void;
}>((props, ref) => {

  const {
    title,
    inputState,
    inputType,
    defaultValue,
    placeholder,
    disabled = false,
    hidden = false,
    extra,
    multiline = false,
    minRows = 4,
    maxRows = 4,
    select = false,
    options,
    onInputChange,
  } = props;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [ state, setState ] = inputState || [undefined, undefined];

  const { t } = useTranslation();

  const onChange: InputProps["onChange"] = (_) => {
    setState?.(_.currentTarget.value);
  };

  const onSelectChange = (_: string) => {
    setState?.(_);
  };

  const onChooseFileClick = () => {
    fileInputRef.current!.click();
  };

  const onRemoveFileClick = () => {
    fileInputRef.current!.value = '';
    onInputChange?.();
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        gap: "4px",
      }}
    >
      <T.Typography
        style={{
          flexShrink: 0,
          width: itemTitleWidth,
          wordBreak: "keep-all",
          color: theme.palette.dark,
          // match the top padding of ANTD input
          paddingTop: "4px",
        }}
      >
        {title}
      </T.Typography>
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <div
          style={{
            display: hidden ? "none" : undefined,
          }}
        >
          {select ?
            <T.Select
              style={{
                minWidth: "144px",
              }}
              placeholder={placeholder}
              defaultValue={defaultValue !== undefined ? defaultValue : placeholder}
              disabled={disabled}
              options={options}
              onChange={onSelectChange}
            />
          : multiline ?
            <T.TextArea
              ref={ref}
              autoSize={{
                minRows: minRows,
                maxRows: maxRows,
              }}
              placeholder={placeholder}
              defaultValue={defaultValue !== undefined ? defaultValue : placeholder}
              disabled={disabled}
              spellCheck={false}
            />
          :
            <T.Input
              ref={toolbox.reactDuplicateRef(fileInputRef, ref as React.ForwardedRef<HTMLInputElement>)}
              style={{
                ...(inputType === "file" && {
                  display: "none",
                } satisfies CSSProperties),
              }}
              type={inputType}
              placeholder={placeholder}
              defaultValue={defaultValue !== undefined ? defaultValue : placeholder}
              disabled={disabled}
              spellCheck={false}
              onChange={onInputChange}
              {...(inputState && {
                value: state,
                onChange: onChange,
              } satisfies InputProps)}
            />
          }
          {inputType === "file" &&
            <div
              style={{
                display: "flex",
                gap: "8px",
              }}
            >
              <T.Button
                type={"default"}
                onClick={onChooseFileClick}
              >
                {`${t("choose_file")}`}
              </T.Button>
              <T.Button
                type={"default"}
                onClick={onRemoveFileClick}
              >
                {`${t("remove_file")}`}
              </T.Button>
            </div>
          }
        </div>
        {extra}
      </div>
    </div>
  );
});

const TrayIconPicker: React.FC<{
  currentTrayIconState: types.ReactNonNullableState<number>;
}> = (props) => {

  const {
    currentTrayIconState: [
      currentTrayIcon,
      setCurrentTrayIcon,
    ],
  } = props;

  const onClick = (_: number) => {
    setCurrentTrayIcon(_);
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
      }}
    >
      {bridge.TrayIcons.map((s, i) => (
        <img
          key={i}
          style={{
            cursor: "pointer",
            opacity: i == currentTrayIcon ? 1 : .4,
            transition: "opacity .2s",
          }}
          src={s}
          onClick={() => onClick(i)}
        />
      ))}
    </div>
  );
};

const getProxy = (rawProxy: string): string => {
  return rawProxy.replace(/^\s*-\s*/, '');
};

const itemTitleWidth: React.CSSProperties["width"] = "200px";

export default UserApp;

import React, { MouseEventHandler, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Outlet, useLocation, useMatch } from "react-router-dom";
import bridge from "~src/backend/bridge";
import T from "~src/components";
import R from "~src/res";
import routes from "~src/routes/index";
import theme from "~src/theme";
import toolbox from "~src/toolbox";
import * as types from "~src/types";

const topBarHeight: React.CSSProperties["height"] = "64px";
const contentTopMargin: React.CSSProperties["marginTop"] = "60px";
const contentBottomMargin: React.CSSProperties["marginBottom"] = "95px";
const webWidth: React.CSSProperties["width"] = "1140px";

const User: React.FC = () => {

  const userIconRef = useRef<SVGSVGElement>(null);
  const menuRef = useRef<types.TPopoverInstance>(null);
  const changePasswordRef = useRef<types.ChangePasswordInstance>(null);

  const [ username, setUsername ] = useState<string>();
  const [ role, setRole ] = useState<string>();

  const location = useLocation();
  const navigate = toolbox.useNavigate();
  const { t } = useTranslation();
  const styles = useStyles();

  const currentPath = useMemo(
    () => location.pathname.split("/").at(-1)!,
    []
  );

  const onTabClick = (tab: string) => {
    navigate(tab);
  };

  const onAccountClick = () => {
    // menuRef.current!.open(true);
  };

  const onChangePasswordClick = () => {
    changePasswordRef.current?.setOpen(true);
  };

  const onSignOffClick = async () => {
    await bridge.logout();
    navigate("/login", { replace: true });
  };

  const userChildren = routes.userChildren.filter((_) => {
    if (_.path === "agent" && (role === undefined || role === bridge.UserRole.Client || role === bridge.UserRole.SecondaryAgent)) {
      return false;
    }
    if (_.path === "coupon" && (role === undefined || role === bridge.UserRole.Client)) {
      return false;
    }
    return true;
  });

  const menuItems: types.MenuProps["items"] = [
    {
      key: "username",
      label: (<>
        <T.Typography
          style={{
            fontSize: "16px",
            fontWeight: 600,
            color: theme.palette.dark,
          }}
        >
          {username}
        </T.Typography>
      </>),
    },
    {
      key: "changePassword",
      label: (<>
        <T.Typography
          style={{
            display: "block",
            fontSize: "16px",
            color: theme.palette.dark,
          }}
          onClick={onChangePasswordClick}
        >
          {t("change_password")}
        </T.Typography>
      </>),
    },
    {
      key: "signOff",
      label: (<>
        <T.Typography
          style={{
            display: "block",
            fontSize: "16px",
            color: theme.palette.dark,
          }}
          onClick={onSignOffClick}
        >
          {t("sign_out")}
        </T.Typography>
      </>),
    },
  ];

  useEffect(() => {
    return toolbox.useAsyncEffectOnce(async () => {
      const r = await bridge.isOnline();
      if (r?.status !== "OK") {
        navigate("/login");
      }
      setUsername(r?.data.username);
      setRole(r?.data.role);
    });
  }, []);

  const renderUserMenu: JSX.Element = (<>
    <T.Popover
      ref={menuRef}
      anchor={userIconRef}
      position="BottomRight"
    >
      <div
        style={{
          padding: "8px 16px",
          border: `1px solid ${theme.palette.blackMask(.4)}`,
          borderRadius: "8px",
          background: theme.palette.whiteMask(.8),
          backdropFilter: "blur(2px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <T.Typography
          style={{
            fontSize: "18px",
            fontWeight: 600,
            color: theme.palette.dark,
          }}
        >
          {username}
        </T.Typography>
        <T.Gap
          height={16}
        />
        <T.Typography
          style={{
            fontSize: "18px",
            color: theme.palette.dark,
          }}
          onClick={onSignOffClick}
        >
          Sign-off
        </T.Typography>
      </div>
    </T.Popover>
  </>);

  const renderModal: JSX.Element = (<>
    <T.ChangePassword
      ref={changePasswordRef}
      style={{
        overlay: {
          zIndex: theme.zIndex.modal,
        },
      }}
      appElement={document.body}
    />
  </>);

  const render: JSX.Element = (<>
    <div
      style={{
        width: "100vw",
        height: "100vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      className={styles.hideScrollbar}
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: theme.zIndex.topBar,
          alignSelf: "stretch",
          background: "#20a0ff",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: webWidth,
            height: "64px",
            padding: "16px",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <R.Logo
            fill={theme.palette.light}
          />
          <T.Dropdown
            menu={{ items: menuItems }}
            placement="bottomRight"
            trigger={["click"]}
          >
            <T.Icon
              ref={userIconRef}
              icon={R.AccountIcon}
              width={24}
              height={24}
              fill={theme.palette.light}
              hover
              onClick={onAccountClick}
            />
          </T.Dropdown>
        </div>
      </div>
      <div
        style={{
          flexGrow: 1,
          width: webWidth,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            margin: `${contentTopMargin} 30px ${contentBottomMargin}`,
            display: "flex",
            gap: "32px",
          }}
        >
          <div
            style={{
              position: "sticky",
              top: `calc(${topBarHeight} + ${contentTopMargin})`,
              height: `calc(100vh - ${topBarHeight} - ${contentTopMargin} - ${contentBottomMargin})`,
              overflowY: "hidden",
              flexShrink: 0,
              width: "20.83%",
            }}
          >
            <T.Menu
              style={{
                minHeight: "100%",
              }}
              defaultSelectedKeys={[currentPath]}
              items={userChildren.map(({path}) => ({
                key: path,
                label: t(path),
                // fixme: Is there a way to make JSX evaluate the tag field? This workaround is TOO UGLY
                icon: (() => {
                  const _ = getIcon(path);
                  return <_/>;
                })(),
                children: null,
              }))}
              onClick={(_) => onTabClick(_.key)}
            />
          </div>
          {/*<div*/}
          {/*  style={{*/}
          {/*    width: "25%",*/}
          {/*    display: "flex",*/}
          {/*    flexDirection: "column",*/}
          {/*    gap: "16px",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {userChildren.map(({path}) => {*/}
          {/*    const Icon = getIcon(path);*/}
          {/*    return (*/}
          {/*      <div*/}
          {/*        key={path}*/}
          {/*        style={{*/}
          {/*          height: "40px",*/}
          {/*          cursor: "pointer",*/}
          {/*          display: "flex",*/}
          {/*          alignItems: "center",*/}
          {/*          gap: "8px",*/}
          {/*        }}*/}
          {/*        onClick={(event) => onTabClick(event, path)}*/}
          {/*      >*/}
          {/*        <Icon />*/}
          {/*        <T.Typography*/}
          {/*          style={{*/}
          {/*            color: theme.palette.dark,*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          {path}*/}
          {/*        </T.Typography>*/}
          {/*      </div>*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</div>*/}
          {/*<T.Divider*/}
          {/*  style={{*/}
          {/*    margin: "0px 16px",*/}
          {/*  }}*/}
          {/*  vertical*/}
          {/*/>*/}
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  </>);

  return (<>
    {render}
    {/*{renderUserMenu}*/}
    {renderModal}
  </>);
};

const useStyles = createUseStyles({
  hideScrollbar: {
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
});

const iconMap: {[_: string]: React.FC} = {
  order: R.AccountBookOutlined,
  app: R.AppstoreAddOutlined,
  task: R.HddOutlined,
};

const getIcon = (path: string): React.FC => {
  return iconMap[path] || R.MenuOutlined;
};

export default User;

import React from "react";

interface TImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
}

const TImg = React.forwardRef<HTMLImageElement, TImgProps>((props, ref) => {

  const {
    style,
    ...restProps
  } = props;

  return (
    <img
      ref={ref}
      style={{
        display: "block",
        ...style,
      }}
      {...restProps}
    />
  );
});

export default TImg;

import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import bridge from "~src/backend/bridge";
import T from "~src/components";
import theme from "~src/theme";
import toolbox from "~src/toolbox";
import * as types from "~src/types";

const Register: React.FC = () => {

  const navigate = toolbox.useNavigate();
  const { t } = useTranslation();

  const notificationRef = useRef<types.TNotificationInstance>(null);
  const usernameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const passwordInput2Ref = useRef<HTMLInputElement>(null);

  const onRegisterClick = async () => {
    const password = passwordInputRef.current!.value;
    const password2 = passwordInput2Ref.current!.value;
    const username = usernameInputRef.current!.value;

    if (password !== password2) {
      notificationRef.current!.open("ERROR", "Passwords do not match");
      return;
    }

    const r = await bridge.register({
      username: username,
      password: password,
    });
    if (r?.status !== "OK") {
      notificationRef.current!.open("ERROR", r?.data || "unknown");
      return;
    }

    navigate("/purchase");
  };

  const onLoginClick = async () => {
    navigate("/login");
  };

  const render: JSX.Element = (<>
    <div
      style={{
        position: "relative",
        width: "100vw",
        minHeight: "100vh",
        // background: theme.palette.blackMask(.1),
        background: `linear-gradient(${theme.palette.blackMask(.05)}, ${theme.palette.primary}80)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          padding: "48px",
          background: theme.palette.whiteMask(.8),
          borderRadius: "16px",
          boxShadow: `0 8px 32px ${theme.palette.blackMask(.05)}`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <T.Typography
          style={{
            alignSelf: "center",
            fontSize: "32px",
            color: theme.palette.dark,
          }}
        >
          {t("registration")}
        </T.Typography>
        <T.Gap
          height={32}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <T.Input
            ref={usernameInputRef}
            placeholder={t("username")}
          />
          <T.Input
            ref={passwordInputRef}
            type="password"
            placeholder={t("password")}
          />
          <T.Input
            ref={passwordInput2Ref}
            type="password"
            placeholder={t("password2")}
          />
        </div>
        <T.Gap
          height={48}
        />
        <div
          style={{
            display: "grid",
            gridAutoRows: "32px",
            gap: "16px",
          }}
        >
          <T.Button
            onClick={onRegisterClick}
          >
            {t("register")}
          </T.Button>
          <T.Button
            type={"link"}
            onClick={onLoginClick}
          >
            {t("sign_in")}
          </T.Button>
        </div>
      </div>
    </div>
  </>);

  const renderNotification: JSX.Element = (<>
    <T.Notification
      ref={notificationRef}
    />
  </>);

  return (<>
    {render}
    {renderNotification}
  </>);
};

export default Register;

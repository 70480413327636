import { Input, InputProps, InputRef } from "antd";
import classNames from "classnames";
import React, { useMemo, useRef } from "react";
import { createUseStyles } from "react-jss";
import theme from "~src/theme";
import toolbox from "~src/toolbox";

interface TInputProps extends React.ComponentPropsWithRef<"input"> {
  placeholderStyle?: React.CSSProperties;
}

const TInput = React.forwardRef<HTMLInputElement, TInputProps>((props, ref) => {

  const {
    className,
    type,
    placeholderStyle: constPlaceholderStyle,
    ...restProps
  } = props;

  const styles = useStyles({
    placeholder: {
      color: theme.palette.blackMask(.3),
      ...constPlaceholderStyle,
    } satisfies typeof constPlaceholderStyle,
  });

  const defaultClassNames = useMemo(() => {
    switch (type) {
      case "file": return styles.inputFile;
      default: return classNames(styles.input, styles.inputPlaceholder);
    }
  }, [
    type,
  ]);

  return (
    <input
      ref={ref}
      className={classNames(
        defaultClassNames,
        className,
      )}
      type={type}
      {...restProps}
    />
  );
});

const useStyles = createUseStyles({
  input: {
    minHeight: "24px",
    padding: "8px",
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.blackMask(.06)}`,
    boxShadow: `0 2px 8px ${theme.palette.blackMask(.05)}`,
    borderRadius: "4px",
    color: theme.palette.blackMask(.8),
    "&:focus": {
      outline: `1px solid ${theme.palette.blackMask(.4)}`,
    },
  },
  inputFile: {
    "&::file-selector-button": {
      marginRight: "8px",
      // copied from TButton
      padding: "4px 8px",
      cursor: "pointer",
      borderRadius: "999px",
      border: `1px solid ${theme.palette.blackMask(.6)}`,
      boxShadow: `0 2px 8px ${theme.palette.blackMask(.08)}`,
      background: theme.palette.blackMask(.01),
      "&:hover": {
        background: theme.palette.blackMask(.025),
      },
      "&:active": {
        background: theme.palette.blackMask(.05),
      },
    },
  },
  inputPlaceholder: (styles: Styles) => ({
    "&::placeholder": styles.placeholder,
  }),
}, {
  link: true,
});

interface Styles {
  placeholder: React.CSSProperties;
}

interface TAntInputProps extends InputProps {
}

const TAntInput = React.forwardRef<HTMLInputElement, TAntInputProps>((props, ref) => {

  const {
    ...restProps
  } = props;

  return (
    <Input
      ref={(_) => {
        if (_) toolbox.reactPropagateRef(ref, _.input);
      }}
      {...restProps}
    />
  );
});

// export default TInput;
export default TAntInput;

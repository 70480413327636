import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import bridge from "~src/backend/bridge";
import T from "~src/components";
import theme from "~src/theme";
import toolbox from "~src/toolbox";
import * as types from "~src/types";

const UserTask: React.FC = () => {

  const notificationRef = useRef<types.TNotificationInstance>(null);

  const [ tasks, setTasks ] = useState<types.Task[]>();

  const { t } = useTranslation();

  const onDownloadClick = (taskId: number) => {
    // todo: download
  };

  useEffect(() => {
    return toolbox.useAsyncEffectOnce(async () => {
      const r = await bridge.listTasks();
      if (r?.status !== "OK") {
        notificationRef.current!.open("ERROR", "Unable to retrieve tasks");
        return;
      }
      r.data.map(_ => {
        _.created_at = toolbox.formatDate(_.created_at);
        return _;
      });
      setTasks(r.data.sort((a, b) => b.id - a.id));
    });
  }, []);

  const render: JSX.Element = (<>
    <T.Table
      dataSource={tasks}
      rowKey={(_) => _.id}
    >
      <T.Table.Column
        title="ID"
        dataIndex="id"
        key="id"
      />
      <T.Table.Column
        title={t("status")}
        dataIndex="status"
        key="id"
        render={(_: string) => (
          <span
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {t(`task_${_.toLowerCase()}`)}
          </span>
        )}
      />
      <T.Table.Column
        title={t("name")}
        dataIndex={["data", "name"]}
        key="id"
      />
      <T.Table.Column
        title={t("platform")}
        dataIndex={["data", "platform"]}
        key="id"
      />
      {/*<T.Table.Column*/}
      {/*  title="URL"*/}
      {/*  dataIndex={["data", "url"]}*/}
      {/*  key="id"*/}
      {/*/>*/}
      {/*<T.Table.Column*/}
      {/*  title="Colour"*/}
      {/*  dataIndex={["data", "colour"]}*/}
      {/*  key="id"*/}
      {/*/>*/}
      <T.Table.Column
        title={t("version")}
        dataIndex={["data", "version"]}
        key="id"
      />
      <T.Table.Column
        title={t("testing_build")}
        dataIndex={["data", "test_build"]}
        key="id"
        render={(_: boolean) => (
          <span
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {t(_ ? "yes" : "no")}
          </span>
        )}
      />
      <T.Table.Column
        title={t("created_at")}
        dataIndex="created_at"
        key="id"
      />
      <T.Table.Column
        title={t("action")}
        key="id"
        render={(_, record: types.Task) => (record.status === "Finished" &&
          <T.Button
            href={`/download/${record.download_path}`}
            onClick={() => onDownloadClick(record.id)}
          >
            Download
          </T.Button>
        )}
      />
    </T.Table>
  </>);

  const renderNotification: JSX.Element = (<>
    <T.Notification
      ref={notificationRef}
    />
  </>);

  return (<>
    {render}
    {renderNotification}
  </>);
};

const Item: React.FC<{
  task: types.Task;
  onClick: (task: types.Task) => void;
}> = (props) => {

  const {
    task,
    onClick,
  } = props;

  return (
    <T.Div
      style={{
        position: "relative",
        height: "64px",
        padding: "8px",
        boxSizing: "border-box",
        cursor: "pointer",
        borderRadius: "8px",
        background: toolbox.colourHexAlpha(task.data.colour, .5),
      }}
      onClick={() => onClick(task)}
    >
      <T.Typography
        style={{
          color: theme.palette.dark,
        }}
      >
        {task.data.name}
      </T.Typography>
    </T.Div>
  );
};

export default UserTask;

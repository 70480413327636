import { Select, SelectProps } from "antd";
import { BaseSelectRef } from "rc-select";
import React from "react";

const { Option } = Select;

interface TSelectProps extends SelectProps {
}

const _TAntSelect = React.forwardRef<BaseSelectRef, TSelectProps>((props, ref) => {

  const {
    ...restProps
  } = props;

  return (
    <Select
      ref={ref}
      {...restProps}
    />
  );
});

const TAntSelect: typeof _TAntSelect & {
  Option: typeof Option;
} = Object.assign(
  _TAntSelect,
  { Option },
);

export default TAntSelect;
export type {
  TSelectProps,
};

import React from "react";
import { Tabs, TabsProps } from "react-tabs";

interface TTabsProps extends TabsProps {
}

const TTabs: React.FC<TTabsProps> = (props) => {
  return (
    <Tabs
      {...props}
    />
  );
};

export default TTabs;

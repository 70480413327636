import React from "react";
import { TabList, TabListProps } from "react-tabs";

interface TTabListProps extends TabListProps {
}

const TTabList = React.forwardRef<HTMLUListElement, TTabListProps>((props, ref) => {
  return (
    <TabList
      ref={ref}
      {...props}
    />
  );
});

export default TTabList;
